import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import "./Sidebar.css";
import {
  SidebarMenuTitleIcons,
  SidebarRestaurantMenuTitleIcons,
} from "../../../utils/SidebarMenu";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { logoutSuperAdmin } from "../../../services/actions/superAdminAction";
import { logoutStoreAdmin } from "../../../services/actions/store/auth.action";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Sidebar() {
  const dispatch = useDispatch();

  const { isAdmin, isAuthenticated } = useSelector(
    (state) => state.isSuperAdmin && state.isSuperAdmin
  );
  const { isStoreAuthenticated } = useSelector((state) => state.isStoreAdmin);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  let location = useLocation();

  const [active, setActive] = React.useState("/");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setActive(
      location.pathname.substring(1) === ""
        ? "Users"
        : location.pathname.substring(1) === "Store-Request"
        ? "Stores"
        : // : location.pathname.substring(1) === "orders-detail"
        // ? "Orders"
        location.pathname.substring(1) === "orders-detail"
        ? "All Orders"
        : location.pathname.substring(1) === "edit-profile"
        ? "Profile"
        : location.pathname.substring(1) === "edit-time-slot"
        ? "Profile"
        : location.pathname.substring(1) === "all-orders"
        ? "All Orders"
        : location.pathname.substring(1) === "promo-code"
        ? "Promo code"
        : location.pathname.substring(1) === "promotions"
        ? "Promo code"
        : location.pathname.substring(1) === "add-menu"
        ? "Menu"
        : location.pathname.substring(1) === "edit-menu"
        ? "Menu"
        : location.pathname.substring(1) === "tax-list"
        ? "Tax"
        : location.pathname.substring(1) === "courier"
        ? "Courier"
        : location.pathname.substring(1) === "vehicle"
        ? "Vehicle"
        : location.pathname.substring(1) === "add-vehicle"
        ? "Vehicle"
        : location.pathname.substring(1) === "add-courier"
        ? "Courier"
        : location.pathname.substring(1) === "tax"
        ? "Tax"
        : location.pathname.substring(1) === "add-tax"
        ? "Tax"
        : location.pathname.substring(1)
    );
  }, [active, location]);

  let sideBarMenus =
    isAdmin === "true"
      ? SidebarMenuTitleIcons
      : SidebarRestaurantMenuTitleIcons;

  const logout = () => {
    dispatch(logoutSuperAdmin(sideBarMenus));
    // return <Redirect to="/login" />;
  };

  console.log(sideBarMenus, "sideBarMenus", isAdmin);

  return (
    <div>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div className="sidebar_logo">
            <img
              src="assets/images/dashboard-logo.png"
              alt=""
              srcSet=""
              className="sidebar_logo_icon"
            />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        <List>
          {sideBarMenus &&
            sideBarMenus.map((menu, index) => (
              <Link
                key={index}
                onClick={() =>
                  menu.title === "Logout" && isAdmin === "true"
                    ? logout()
                    : menu.title === "Logout" && dispatch(logoutStoreAdmin())
                }
                to={
                  ("/",
                  menu.title === "Logout"
                    ? isAdmin === "true"
                      ? "login"
                      : "restaurant/login"
                    : menu.title === "Users"
                    ? ""
                    : menu.title === "All Orders"
                    ? "all-orders"
                    : menu.title === "Store-Request"
                    ? "Stores"
                    : menu.title === "Promo code"
                    ? "promo-code"
                    : menu.title)
                }
              >
                <ListItem
                  button
                  className={menu.title === active ? "menu_btn_active" : null}
                >
                  <ListItemIcon>{menu.icons}</ListItemIcon>
                  <ListItemText primary={menu.title} />
                </ListItem>
              </Link>
            ))}
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}

export default Sidebar;
