import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { EditIcon } from "../../../../utils/Icons";
import Sidebar from "../../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../../Heading/Heading";
import useFetch from "../../../../hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../../../helper/ApiList";
import { singleOrderDetails } from "../../../../services/actions/orderAction";
import Moment from "react-moment";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function AllOrders() {
  const classes = useStyles();
  let history = useHistory();
  const { token } = useSelector((state) => state.isStoreAdmin);
  const { data } = useFetch(Api.getAllOrders, token, "get");
  const dispatch = useDispatch();
  const columns = [
    { title: "Sr. No", field: "index" },
    { title: "Order No", field: "orderNumber" },
    { title: "Items", field: "items" },
    // { title: "Pic Up ", field: "picUpLocation" },
    { title: "Delivery ", field: "deliveryLocation" },
    { title: "Price", field: "price" },
    {
      title: "Delivery Date & Time",
      field: "deliveryDateAndTime",
      render: (item) => (
        <span>
        
        {item?.deliveryDateAndTime?moment(item?.deliveryDateAndTime).fromNow():""} 

        </span>
      ),
    },

    {
      title: "Status",
      field: "status",
      render: (item) => (
        <span
          className={
            item.status === "PICKEDUP"
              ? "text-info font-weight-bold"
              : item.status === "CONFIRMED"
              ? "text-warning font-weight-bold"
              : item.status === "DELIVERED"
              ? "text-success font-weight-bold"
              : "text-danger font-weight-bold"
          }
        >
          {item.status}
        </span>
      ),
    },
  ];

  // const tableData = [

  // ];

  const tableData =
    data.length === 0
      ? []
      : data.reverse().map((e, i) => {
          return {
            index: i + 1,
            orderNumber: e?.orderId,
            items: e?.products[0]?.quantity,
            // picUpLocation: "Mohali Chandigarh",
            deliveryLocation: e?.userId?.address,

            price: "$" + e?.netAmount,
            deliveryDateAndTime: e?.orderDeliveredAt,
            status: e?.orderStatus,
            data: e,
          };
        });
  const actions = [
    {
      icon: EditIcon,
      tooltip: "Edit Order",
      onClick: (event, rowData) => {
        dispatch(singleOrderDetails(rowData.data));
        history.push("/orders-detail");
      },
    },
  ];

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        <div className="mt-2 table-set">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
            }}
            data={tableData && tableData}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        </div>
      </main>
    </div>
  );
}
