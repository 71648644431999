import React, { useState } from "react";
import Sidebar from "../Super-Admin/Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import "./Profile.css";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useStyles } from "../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { Api } from "../../helper/ApiList";
import { loaderAction } from "./../../services/actions/loaderAction";
import { useAlert } from "react-alert";

export default function EditProfile() {
  const classes = useStyles();
  let editProfileBtn = true;
  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );

  const alert = useAlert();

  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleSubmit = async () => {
    if (!currentPassword || !newPassword) {
      alert.info("All fields are required");
      return false;
    } else {
      dispatch(loaderAction(true));

      let config = {
        headers: { Authorization: `${adminToken}` },
      };

      const { data } = await axios.put(
        Api.changePassword,
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
        },
        config
      );

      const { response } = data;
      if (response.status.statusCode === 200) {
        alert.success("Password has been updated successfully");

        history.push("/Profile");
      } else {
        alert.info(response.status.customMessage);
      }
      dispatch(loaderAction(false));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading editProfileBtn={editProfileBtn} />

          <Row className="pt-5 edit_profile_form">
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  placeholder="Current Password"
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button className="edit_profile_btn" onClick={() => handleSubmit()}>
            {" "}
            Submit
          </Button>
        </main>
      </div>
    </>
  );
}
