import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { DeleteIcon, EyeIcon } from "../../../utils/Icons";
import MaterialTable from "material-table";
import { category } from "../../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../utils/useStyles";
import { useAlert } from "react-alert";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Avatar from "@mui/material/Avatar";
export default function Category() {
  const classes = useStyles();
  const alert = useAlert();

  const dispatch = useDispatch();

  const { categoryList } = useSelector((state) => state.categoryList);
  const { adminToken } = useSelector((state) => state.isSuperAdmin);

  // let token = localStorage.getItem("superAdminToken");
  React.useEffect(() => {
    dispatch(category(adminToken));
  }, [dispatch, adminToken]);

  const deleteCategory = async (id) => {
    let config = {
      headers: {
        Authorization: `${adminToken}`,
      },
    };
    const { data } = await axios.put(
      Api.deleteCategory,
      { categoryId: id },
      config
    );

    if (data.response.status.statusCode === 200) {
      alert.success("Category has been deleted successfully");
    } else {
      alert.error(data.response.status.customMessage);
    }

    dispatch(category(adminToken));
  };

  const columns = [
    { title: "Sr. No", field: "index" },
    {
      title: "Img",
      field: "image",
      render: (item) => (
        <>
          {item.image ? (
            <LazyLoadImage
              alt={""}
              effect="blur"
              src={process.env.REACT_APP_IMAGE_URL + item.image}
              height="50"
              width="50"
            />
          ) : (
            <Avatar src="" sx={{ width: 24, height: 24 }} />
          )}
        </>
      ),
    },
    { title: "Category Name", field: "name" },
    { title: "Created On", field: "createdAt" },
  ];

  const actions = [
    {
      icon: DeleteIcon,
      tooltip: "Delete User",
      onClick: (event, rowData) => deleteCategory(rowData._id),
    },
  ];

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading addCategoryBtn={true} />

        <div className="mt-2">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
            }}
            data={categoryList && categoryList}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        </div>
      </main>
    </div>
  );
}
