import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import "./OrderDetails.css";
import { Button, Col, Row } from "react-bootstrap";
import { MenuItem } from "@material-ui/core";
import { Select } from "@mui/material";
import { useStyles } from "../../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";

import useFetch from "./../../../hooks/useFetch";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Moment from "react-moment";
import moment from "moment";
export default function OrderDetails() {
  const { root, content, toolbar } = useStyles();
  const [orderStatus, setOrderStatus] = React.useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { singleOrder } = useSelector((state) => state.singleOrder);
  const { token } = useSelector((store) => store.isStoreAdmin);
  const [storeInfo, setStoreInfo] = useState(null);
  const alert = useAlert();
  const { data, type } = useFetch(
    Api.showStoreAvailableServiceProfile,
    token,
    "get"
  );

  const handleChange = (event) => {
    setOrderStatus(event.target.value);
  };

  React.useEffect(() => {
    if (singleOrder) {
      setOrderStatus(singleOrder?.orderStatus);
      setStoreInfo(data[0]);
    }
  }, [singleOrder]);

  console.log(singleOrder, "singleOrder");

  const handleSubmit = async () => {
    let config = {
      headers: { Authorization: `${token}` },
    };

    try {
      dispatch(loaderAction(true));
      const { data } = await axios.put(
        Api.updateOrder,
        {
          orderId: singleOrder?._id,
          orderStatus: orderStatus,
        },
        config
      );
      const { response } = data;
      if (response.status.statusCode === 200) {
        alert.success("Order has been updated successfully.");
        history.push("/all-orders");
      } else {
        alert.info(response.status.customMessage);
      }
      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };
  return (
    <div className={root}>
      <Sidebar />
      <main className={content}>
        <div className={toolbar} />
        <Heading />

        <div className="order">
          <Row>
            <Col md={6}>
              <div className="heading_order">
                <h6>{storeInfo?.name}</h6>
                <p>{storeInfo?.address}</p>
              </div>
              <img
                src={
                  type == "Food"
                    ? "assets/images/food.svg"
                    : type == "Courier"
                    ? "assets/images/courier.svg"
                    : "assets/images/grocery.svg"
                }
                alt=""
                className="img_alot"
              />
            </Col>
            <Col md={6}>
              <div className="text-right order_status">
                <span>Order Status:</span>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className={
                    orderStatus === "PICKEDUP"
                      ? "text-info font-weight-bold"
                      : orderStatus === "CONFIRMED"
                      ? "text-warning font-weight-bold"
                      : orderStatus === "ONTHEWAY"
                      ? "text-primary font-weight-bold"
                      : orderStatus === "DELIVERED"
                      ? "text-success font-weight-bold"
                      : "text-danger font-weight-bold"
                  }
                  value={orderStatus}
                  label="Order Status"
                  onChange={handleChange}
                >
                  <MenuItem
                    value={"DELIVERED"}
                    className="delivered-clr text-success"
                  >
                    Delivered
                  </MenuItem>
                  <MenuItem value={"CONFIRMED"} className="text-warning">
                    Confirmed
                  </MenuItem>
                  <MenuItem value={"PENDING"} className="text-danger">
                    Pending
                  </MenuItem>
                  <MenuItem value={"PICKEDUP"} className="text-info">
                    Picked Up
                  </MenuItem>
                  <MenuItem value={"ONTHEWAY"} className="text-info">
                    On the way
                  </MenuItem>
                </Select>
              </div>
            </Col>
          </Row>

          <div>
            <h5 className="items_heading">Items</h5>
            {singleOrder?.products.length > 0 &&
              singleOrder?.products?.map((item, index) => (
                <>
                  <div className="items_container" key={index}>
                    <div>
                      <img
                        src={
                          process.env.REACT_APP_IMAGE_URL + item?.productImage
                        }
                        alt=""
                      />
                    </div>
                    <div>
                      <h5>{item?.productName}</h5>
                      <p>
                        {item?.quantity + " " + "*$" + item?.price}
                        <span>${item?.quantity * item?.price}</span>
                      </p>
                    </div>
                  </div>
                  <hr />
                </>
              ))}

            <div className="d-flex justify-content-between items_total">
              <div>
                <h5>Items Total</h5>
                <h5>Tax</h5>
                <h5>Delivery Charge</h5>
                <h5>Promo Discount</h5>
              </div>
              <div>
                <p>
                  {singleOrder?.netAmount ? "$" + singleOrder?.netAmount : "$0"}
                </p>
                <p>
                  {singleOrder?.taxAmount ? "$" + singleOrder?.taxAmount : "$0"}
                </p>
                <p>
                  {singleOrder?.deliveryFee
                    ? "$" + singleOrder?.deliveryFee
                    : "$0"}
                </p>
                <p>
                  {singleOrder?.discountAmount
                    ? "$" + singleOrder?.discountAmount
                    : "$0"}
                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between items_total">
              <div>
                <h5>SubTotal</h5>
              </div>
              <div>
                <p>${parseInt(singleOrder?.subTotalAmount)}</p>
              </div>
            </div>
            <h5 className="items_heading">Order Details</h5>
            <div className="d-flex justify-content-between items_total">
              <div>
                <h5>Order Number</h5>
                <h5>Payment</h5>
                <h5>Time</h5>
              </div>
              <div>
                <p>{singleOrder?.serialNumberOrder}</p>
                <p>{singleOrder?.paymentType}</p>
                <p>
        {singleOrder?.orderEstimateDeliveryDate?moment(singleOrder?.orderEstimateDeliveryDate).fromNow():""} 
                </p>
              </div>
            </div>
            <h5 className="items_heading">Customer Details</h5>
            <div className="d-flex justify-content-between items_total">
              <div>
                <h5>Name</h5>
                <h5>Contact</h5>
                <h5>Delivery Address</h5>
              </div>
              <div>
                <p>
                  {singleOrder?.userId?.firstName +
                    " " +
                    singleOrder?.userId?.lastName}
                </p>
                <p> {singleOrder?.userId?.contactNumber}</p>
                <p> {singleOrder?.userId?.address}</p>
              </div>
            </div>

            <Button className="btn_submit" onClick={() => handleSubmit()}>
              Submit
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
