import {
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  USER_FAIL,
  USER_REQUEST,
  USER_SUCCESS,
} from "../constants/Constants";
import { loaderAction } from "./loaderAction";
import { Api } from "../../helper/ApiList";
import axios from "axios";
import store from "../store";

export const allUsers = () => async (dispatch) => {
  const { adminToken } = store.getState().isSuperAdmin;
  dispatch(loaderAction(true));
  dispatch({ type: USER_REQUEST });

  const { data } = await axios({
    url: Api.getUsersList,
    method: "POST",

    headers: { Authorization: adminToken },
  });
  if (data.response) {
    const { status, userData } = data.response;

    let arr = [];

    userData.forEach((user, i) => {
      user.index = i + 1;
      arr.push(user);
    });

    if (status.statusCode === 200) {
      dispatch({ type: USER_SUCCESS, payload: arr });
      dispatch(loaderAction(false));
    }
  } else {
    dispatch(loaderAction(false));
    dispatch({ type: USER_FAIL, payload: data });
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  const { adminToken } = store.getState().isSuperAdmin;

  dispatch(loaderAction(true));

  dispatch({ type: DELETE_USER_REQUEST });
  const { data } = await axios({
    method: "post",

    url: Api.deleteUser,
    data: { userId: userId },

    headers: { Authorization: adminToken },
  });

  if (data.response) {
    const { status } = data.response;

    if (status.statusCode === 200) {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: { message: "User successfully deleted" },
      });
      dispatch(loaderAction(false));
      dispatch(allUsers());
    }
  } else {
    dispatch(loaderAction(false));
    dispatch({ type: DELETE_USER_FAIL, payload: data.response });
  }
};
