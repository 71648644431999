import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Button, Container } from "react-bootstrap";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";

import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { useStyles } from "../../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loaderAction";

export default function AddVehicle() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );
  const alert = useAlert();

  const [upload, setUpload] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);

  const [inputData, setInputData] = React.useState({
    name: "",
    charges: "",
    image: "",
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    let data = { ...inputData };

    data[name] = value;
    setInputData(data);
  };

  const handleSubmit = async () => {
    let formData = { ...inputData };
    formData["image"] = upload;
    dispatch(loaderAction(true));
    let config = {
      headers: { Authorization: `${adminToken}` },
    };
    try {
      const { data } = await axios.post(Api.addVehicle, formData, config);

      const { response } = data;
      if (response.status.statusCode === 200) {
        alert.success("Vehicle has been successfully added");
        history.push("/vehicle");
      } else {
        alert.info(response.status.customMessage);
      }
      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };

  const handleMenuImage = async (e) => {
    dispatch(loaderAction(true));

    const file = e.target.files;
    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("name", fileData);

    myForm.append("file", fileData);

    const { data } = await axios({
      url: `${Api.fileUpload}`,
      method: "post",
      data: myForm,
    });
    if (data.status === 1) {
      console.log("Upload successfully", data);
      setUpload(data.data);

      alert.success(data.message);
    } else {
      alert.info(data.message);
    }

    dispatch(loaderAction(false));
  };

  const createCategoryImagesChange = (e) => {
    const files = Array.from(e.target.files);

    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
    handleMenuImage(e);
  };

  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading />

          <Container>
            <Row className="pt-5 edit_profile_form">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder=""
                    name="name"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price charges</Form.Label>
                  <Form.Control
                    placeholder="usd"
                    name="charges"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Container>
              <p className="ml-4">Upload Image</p>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={createCategoryImagesChange}
              />
              <div className="file">
                <label htmlFor="contained-button-file" className="file_upload">
                  {imagesPreview.length === 0 ? (
                    <CollectionsOutlinedIcon className="upload_image" />
                  ) : (
                    imagesPreview.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        className="max-w-100 img_preview"
                        alt="Category Preview"
                      />
                    ))
                  )}
                </label>
              </div>
            </Container>

            <Button
              className="edit_profile_btn p-4 pt-2 pb-2 "
              onClick={() => handleSubmit()}
            >
              {" "}
              Submit
            </Button>
          </Container>
        </main>
      </div>
    </>
  );
}
