import * as React from "react";

export default function Loader() {
  return (
    <div className="loader_root">
      <div className="loader">
        <svg viewBox="0 0 32 32" width="42" height="42">
          <circle id="spinner" cx="16" cy="16" r="14" fill="none"></circle>
        </svg>
      </div>
    </div>
  );
}
