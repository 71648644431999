import { DRIVER_REQUEST, DRIVER_SUCCESS } from "../constants/Constants";

const initialState = {
  driverList: null,
};

export const driverReducer = (state = initialState, action) => {
  switch (action.type) {
    case DRIVER_REQUEST:
      return {
        initialState,
      };

    case DRIVER_SUCCESS:
      return {
        ...state,
        driverList: action.payload,
      };

    default:
      return state;
  }
};
