import axios from "axios";
import { Api } from "../../helper/ApiList";
import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_FAIL,
} from "../constants/Constants";
import { loaderAction } from "./loaderAction";
import { SINGLE_ORDER_DETAILS_SUCCESS } from "./../constants/Constants";

export const order = (token) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: ORDER_REQUEST });

  const { data } = await axios({
    method: "post",
    headers: { Authorization: `${token}` },
    url: `${Api.getOrdersList}`,
  });

  if (data.response) {
    console.log(data.response, "svd");
    const { status, result } = data.response;

    dispatch(loaderAction(false));

    if (status.statusCode === 200) {
      if (result.length === 0) {
        dispatch({ type: ORDER_SUCCESS, payload: result });
        dispatch(loaderAction(false));
      } else {
        dispatch({ type: ORDER_SUCCESS, payload: result });
        dispatch(loaderAction(false));
      }
    }
  } else {
    dispatch({ type: ORDER_FAIL });

    dispatch(loaderAction(false));
  }
};

export const singleOrderDetails = (data) => {
  return async (dispatch) => {
    dispatch({ type: SINGLE_ORDER_DETAILS_SUCCESS, payload: data });
  };
};
