import { modalContants } from "../constants/Constants";

const initialState = {
  openModal: false,
  modalData: null,
};

export const openModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalContants.CLOSE_MODAL:
      return {
        initialState,
      };

    case modalContants.OPEN_MODAL:
      return {
        ...state,
        openModal: action.open,
        modalData: action.payload,
      };

    default:
      return state;
  }
};
