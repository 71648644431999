import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { DeleteIcon, EditIcon, EyeIcon } from "../../../utils/Icons";
import MaterialTable from "material-table";
import { CircularProgress } from "@material-ui/core";
import { driver } from "../../../services/actions/driverAction";
import { useDispatch, useSelector } from "react-redux";
import EyeModal from "../../EyeModal/EyeModal";
import { openModalAction } from "../../../services/actions";
import { useStyles } from "../../../utils/useStyles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Avatar from "@mui/material/Avatar";
import DriverModal from "./DriverModal";
export default function Drivers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { driverList } = useSelector((state) => state.driverList);
  const { adminToken } = useSelector((state) => state.isSuperAdmin);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(false);
  const columns = [
    { title: "Sr. No", field: "index" },
    {
      title: "Img",
      field: "profileImage",
      render: (item) => (
        <>
          {item.profileImage ? (
            <LazyLoadImage
              alt={""}
              effect="blur"
              src={process.env.REACT_APP_IMAGE_URL + item.profileImage}
              height="50"
              width="50"
            />
          ) : (
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 24, height: 24 }}
            />
          )}
        </>
      ),
    },
    { title: "Name", field: "firstName" },
    { title: "City", field: "city" },
    { title: "Country Code", field: "countryCode" },
    { title: "Phone Number", field: "contactNumber" },
    {
      title: "Document",
      field: "document",
      render: (item) => (
        <img
          src={process.env.REACT_APP_IMAGE_URL + item.license}
          alt=""
          border="3"
          height="20"
          width="20"
        />
      ),
    },
  ];

  const actions = [
    {
      icon: EyeIcon,
      tooltip: "View Driver",
      onClick: (event, rowData) => dispatch(openModalAction(true, rowData)),
    },
    {
      icon: EditIcon,
      tooltip: "Update",
      onClick: (event, rowData) => {
        setOpen(true);
        setRowData(rowData);
      },
    },
  ];
  React.useEffect(() => {
    dispatch(driver(adminToken));
  }, [dispatch, adminToken]);

  return (
    <div
      className={classes.root}
      onClick={() => dispatch(openModalAction(false))}
    >
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />
        <EyeModal />
        <div className="mt-2 table-set">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
            }}
            data={driverList && driverList}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        </div>
      </main>
      <DriverModal
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        setRowData={setRowData}
      />
    </div>
  );
}
