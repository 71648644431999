import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  SUPER_ADMIN_RESET,
} from "../constants/AdminConstants";

const initialState = {
  isAuthenticated: false,
  loader: false,
  superAdminData: null,
  isAdmin: false,
  token: null,
  adminToken: null,
  message: null,
};

export const superAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        loader: true,
        isAuthenticated: false,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loader: false,
        superAdminData: action?.payload?.email,
        isAuthenticated: true,
        isAdmin: action?.payload?.isAdmin,
        token: action?.payload?.accessToken,
        adminToken: action?.payload?.accessToken,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        message: action.payload.message,
        superAdminData: action.payload.data,

        loader: false,
      };
    case LOGOUT_REQUEST:
    case SUPER_ADMIN_RESET:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
