import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import DataTable from "../../DataTable/DataTable";
import { DeleteIcon, EditIcon, EyeIcon } from "../../../utils/Icons";
import { Box, CircularProgress } from "@material-ui/core";
import StoreTab from "./StoreTab";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../services/actions/storeAction";
import Loader from "../../Loader/Loader";
import { openModalAction } from "../../../services/actions";
import EyeModal from "../../EyeModal/EyeModal";
import { useStyles } from "../../../utils/useStyles";
import { loaderAction } from "./../../../services/actions/loaderAction";
import { Api } from "./../../../helper/ApiList";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAlert } from "react-alert";
import useFetch from "../../../hooks/useFetch";
import StoreModal from "./StoreModal";

export default function Stores() {
  const classes = useStyles();
  let btnAction = true;
  const dispatch = useDispatch();
  const alert = useAlert();

  const { loader } = useSelector((state) => state.loader);
  const [loading, setLoading] = React.useState(false);

  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );

  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(false);
  const { data } = useFetch(Api.getStoreList, adminToken, "get");

  // console.log(stores, "Sdfsd");

  const columns = [
    { title: "Sr. No", field: "index" },
    // {
    //   title: "Img",
    //   field: "image",
    //   render: (item) => (
    //     <LazyLoadImage
    //       alt={""}
    //       effect="blur"
    //       src={process.env.REACT_APP_IMAGE_URL + item.image}
    //       height="50"
    //       width="50"
    //     />
    //   ),
    // },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Address", field: "address" },
    { title: "Phone Number", field: "contactNumber" },
  ];

  const APIData =
    data.length === 0
      ? []
      : data.map((item, index) => {
          return {
            index: index + 1,
            name: item?.name,
            email: item?.email,
            address: item?.address,
            contactNumber: item?.contactNumber,
          };
        });

  const actions = [
    {
      icon: EyeIcon,
      tooltip: "View User",
      onClick: (event, rowData) => dispatch(openModalAction(true, rowData)),
    },
    {
      icon: EditIcon,
      tooltip: "Update",
      onClick: (event, rowData) => {
        setOpen(true);
        setRowData(rowData);
      },
    },
  ];

  return (
    <div
      className={classes.root}
      onClick={() => dispatch(openModalAction(false))}
    >
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />
        <EyeModal />
        <StoreTab btnAction={btnAction} />

        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <MaterialTable
              options={{
                actionsColumnIndex: -1,
              }}
              data={APIData && APIData}
              columns={columns && columns}
              title=""
              // onRowClick={(event, rowData) => setRowData(rowData)}
              actions={actions && actions}
            />
          )}
        </Box>
      </main>
      <StoreModal
        open={open}
        setOpen={setOpen}
        rowData={rowData}
        setRowData={setRowData}
      />
    </div>
  );
}
