import React from "react";

import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import { DeleteIcon } from "../../../utils/Icons";
import Heading from "../../Heading/Heading";

import { CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { Api } from "../../../helper/ApiList";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { useStyles } from "../../../utils/useStyles";
import { loaderAction } from "../../../services/actions/loaderAction";
import axios from "axios";
import { useAlert } from "react-alert";

export default function PromoCode() {
  const classes = useStyles();
  const { token } = useSelector((state) => state.isStoreAdmin);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { data, fetchAgain } = useFetch(Api.showPromoCode, token, "get");

  const deletePromoCode = async (rowData) => {
    dispatch(loaderAction(true));
    let config = {
      headers: { Authorization: `${token}` },
    };

    const { data } = await axios.put(
      Api.deletePromoCode,
      {
        promocode_id: rowData._id,
      },
      config
    );

    const { response } = data;
    if (response.status.statusCode === 200) {
      alert.success("Promo code has been successfully deleted");
      fetchAgain();
    } else {
      alert.info(response.status.customMessage);
    }
    dispatch(loaderAction(false));
  };

  const columns = [
    { title: "Sr. No", field: "index" },
    { title: "Name", field: "name" },

    { title: "Promo code", field: "promoCode" },
    { title: "Discount Amount", field: "discountAmount" },
    { title: "Discount Percentage", field: "discountPercentage" },
    { title: "Valid Upto", field: "validUpto" },
  ];

  let tableData =
    data && data.length === 0
      ? []
      : data &&
        data.map((item, index) => {
          return {
            index: index + 1,
            _id: item?._id,
            name: item?.name,
            promoCode: item?.code,
            discountAmount: item?.discountAmount,
            discountPercentage: item?.discountPercentage,
            validUpto: item?.validUpTo.slice(0, 10),
            serviceId: item?.serviceId?._id,
          };
        });

  const actions = [
    {
      icon: DeleteIcon,
      tooltip: "Delete Code",
      onClick: (event, rowData) => deletePromoCode(rowData),
    },
  ];

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading addPromoCodeBtn={true} />

        <div className="mt-2">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
            }}
            data={tableData && tableData}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        </div>
      </main>
    </div>
  );
}
