import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { CloseIcon, CheckIcon } from "../../../utils/Icons";
import StoreTab from "./StoreTab";
import { Box, CircularProgress } from "@material-ui/core";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { storeRequest } from "../../../services/actions/storeAction";
import axios from "axios";
import { Api } from "../../../helper/ApiList";
import { loaderAction } from "../../../services/actions/loaderAction";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function StoreRequest() {
  const classes = useStyles();
  const [requestData, setRequestData] = React.useState([]);

  const dispatch = useDispatch();

  let btnAction = true;
  let token = localStorage.getItem("superAdminToken");

  useEffect(() => {
    dispatch(loaderAction(true));

    const getStoreRequest = async () => {
      const { data } = await axios({
        method: "post",
        headers: { Authorization: `${token}` },
        url: `${Api.getRestaurantList}?type=request`,
      });

      if (data.response) {
        const { status, resultData } = data.response;
        setRequestData(resultData);
        dispatch(loaderAction(false));

        if (status.statusCode === 200) {
          dispatch(loaderAction(false));
        }
      }
    };

    getStoreRequest();
  }, [token, dispatch]);

  const columns = [
    { title: "Sr. No", field: "id" },
    {
      title: "Img",
      field: "image",
      render: (item) => (
        <img src={item.img} alt="" border="3" height="40" width="40" />
      ),
    },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Address", field: "address" },
    { title: "Phone Number", field: "contactNumber" },
  ];

  const actions = [
    {
      icon: CheckIcon,
      tooltip: "Save User",
      onClick: (event, rowData) => alert("You saved " + rowData.name),
    },
    {
      icon: CloseIcon,
      tooltip: "Delete User",
      onClick: (event, rowData) => alert("You want to delete " + rowData.name),
    },
  ];

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading />
          <StoreTab btnAction={btnAction} />
          <Box>
            <MaterialTable
              options={{
                actionsColumnIndex: -1,
              }}
              data={requestData && requestData}
              columns={columns && columns}
              title=""
              onRowClick={(event, rowData) => console.log(rowData)}
              actions={actions && actions}
            />
          </Box>
        </main>
      </>
    </div>
  );
}
