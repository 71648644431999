// Users

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAIL = "USER_FAIL";

// Delete user

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

// Store

export const STORE_SIGNUP_REQUEST = "STORE_SIGNUP_REQUEST";
export const STORE_SIGNUP_SUCCESS = "STORE_SIGNUP_SUCCESS";
export const STORE_SIGNUP_FAIL = "STORE_SIGNUP_FAIL";

export const STORE_REQUEST = "STORE_REQUEST";
export const STORE_SUCCESS = "STORE_SUCCESS";
export const STORE_FAIL = "STORE_FAIL";

export const MENU_RESET = "MENU_RESET";
export const MENU_SUCCESS = "MENU_SUCCESS";

// Category
export const CATEGORY_REQUEST = "CATEGORY_REQUEST";
export const CATEGORY_SUCCESS = "CATEGORY_SUCCESS";
export const CATEGORY_FAIL = "CATEGORY_FAIL";

// Add Category
export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL";

// Drivers

export const DRIVER_REQUEST = "DRIVER_REQUEST";
export const DRIVER_SUCCESS = "DRIVER_SUCCESS";
export const DRIVER_FAIL = "DRIVER_FAIL";

// Orders

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAIL = "ORDER_FAIL";

export const SINGLE_ORDER_DETAILS_SUCCESS = "SINGLE_ORDER_DETAILS_SUCCESS";
export const SINGLE_ORDER_DETAILS_RESET = "SINGLE_ORDER_DETAILS_RESET";

// Modal

export const modalContants = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
};
