import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Button, Container } from "react-bootstrap";

import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { useStyles } from "../../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useLocation } from "react-router-dom";
export default function AddNewTax() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );
  const alert = useAlert();

  const search = useLocation().search;
  const query = new URLSearchParams(search).get("query");

  console.log(query);

  // if(query)

  const [inputData, setInputData] = React.useState({
    taxIdentifier: "",
    country: "",
    state: "",
    zipCode: "",
    percentage: "",
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    let data = { ...inputData };

    data[name] = value;
    setInputData(data);
  };

  const handleSubmit = async () => {
    dispatch(loaderAction(true));
    let config = {
      headers: { Authorization: `${adminToken}` },
    };
    try {
      const { data } = await axios.post(Api.addTax, inputData, config);

      const { response } = data;
      if (response.status.statusCode === 200) {
        alert.success("Tax has been successfully added");
        history.push("/tax");
      } else {
        alert.info(response.status.customMessage);
      }
      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading />

          <Container>
            <Row className="pt-5 edit_profile_form">
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Tax Identifier</Form.Label>
                  <Form.Control
                    placeholder=""
                    name="taxIdentifier"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type=""
                    name="country"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder=""
                    name="state"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    placeholder=""
                    name="zipCode"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Percentage</Form.Label>
                  <Form.Control
                    placeholder=""
                    name="percentage"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              className="edit_profile_btn p-4 pt-2 pb-2 "
              onClick={() => handleSubmit()}
            >
              {" "}
              Submit
            </Button>
          </Container>
        </main>
      </div>
    </>
  );
}
