import {
  STORE_SIGNUP_FAIL,
  STORE_SIGNUP_REQUEST,
  STORE_SIGNUP_SUCCESS,
} from "../../constants/StoreConstants";

const initialState = {
  loader: false,
  token: null,
  message: null,
  open: false,
  storeData: null,
};

export const storeUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_SIGNUP_REQUEST:
      return {
        ...initialState,
      };

    case STORE_SIGNUP_SUCCESS:
      return {
        ...state,
        loader: false,
        token: action.payload.accessToken,
        open: true,
        storeData: action.payload.data,
      };
    case STORE_SIGNUP_FAIL:
      return {
        ...state,
        open: false,
        message: action.payload.message,
        loader: false,
      };

    default:
      return state;
  }
};
