import cookie from "js-cookie";

// Set in Cookie management

const setCookie = (key, value) => {
  if (window !== undefined) {
    cookie.set(key, value, {
      // 7 days
      expires: 7,
    });
  }
};

const removeCookie = (key) => {
  if (window !== undefined) {
    cookie.remove(key, {
      // 7 days
      expires: 7,
    });
  }
};

// Get from cookie like token
const getCookie = (key) => {
  if (window !== "undefined") {
    return cookie.get(key);
  }
};

//@ redux management =>>> user

// Auth user after login for vendor

const authenticate = (token, next) => {
  setCookie("access_token", token);
  next();
};

const getAuthentication = () => {
  return getCookie("access_token");
};

const removeAuthentication = (next) => {
  removeCookie("access_token");
  next();
};

// SESSION STORAGE Auth user after login for vendor

const setSession = (token, next) => {
  sessionStorage.setItem("access_token", token);
  next();
};

const getSession = () => {
  return sessionStorage.getItem("access_token");
};

const removeSession = (next) => {
  sessionStorage.removeItem("access_token");
  next();
};
// Auth user after login for vendor
const setEmail = (email, next) => {
  setCookie("e", email);
  next();
};

const setIsAdmin = (isAdmin, next) => {
  setCookie("isAdmin", isAdmin);
  next();
};

const getEmail = () => {
  return getCookie("e");
};

const getIsAdmin = () => {
  return getCookie("isAdmin");
};

const removeEmail = (next) => {
  removeCookie("e");
  next();
};

const removeIsAdmin = (next) => {
  removeCookie("isAdmin");
  next();
};

export {
  setCookie,
  removeCookie,
  getCookie,
  authenticate,
  getAuthentication,
  removeAuthentication,
  setSession,
  getSession,
  removeSession,
  setEmail,
  getEmail,
  removeEmail,
  setIsAdmin,
  getIsAdmin,
  removeIsAdmin,
};
