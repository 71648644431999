import axios from "axios";
import { Api } from "../../helper/ApiList";
import {
  CATEGORY_REQUEST,
  CATEGORY_SUCCESS,
  CATEGORY_FAIL,
  ADD_CATEGORY_REQUEST,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
} from "../constants/Constants";
import { loaderAction } from "./loaderAction";

export const category = (token) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: CATEGORY_REQUEST });

  const { data } = await axios({
    method: "post",
    headers: { Authorization: `${token}` },
    url: `${Api.getCategoriesList}`,
  });

  if (data.response) {
    const { status, result } = data.response;

    dispatch(loaderAction(false));

    if (status.statusCode === 200) {
      if (result.length === 0) {
        dispatch({ type: CATEGORY_SUCCESS, payload: result });
        dispatch(loaderAction(false));
      } else {
        let arr = [];
        result &&
          result.forEach((store, index) => {
            store.index = index + 1;

            arr.push(store);
          });
        dispatch({ type: CATEGORY_SUCCESS, payload: arr && arr });
        dispatch(loaderAction(false));
      }
    }
  } else {
    dispatch({ type: CATEGORY_FAIL });

    dispatch(loaderAction(false));
  }
};
