import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import DataTable from "../../DataTable/DataTable";
import { DeleteIcon, EyeIcon } from "../../../utils/Icons";
import { Box, CircularProgress } from "@material-ui/core";
import StoreTab from "./StoreTab";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../services/actions/storeAction";
import Loader from "../../Loader/Loader";
import { openModalAction } from "../../../services/actions";
import EyeModal from "../../EyeModal/EyeModal";
import { useStyles } from "../../../utils/useStyles";
import { loaderAction } from "../../../services/actions/loaderAction";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAlert } from "react-alert";

export default function Restaurants() {
  const classes = useStyles();
  let btnAction = true;
  const dispatch = useDispatch();
  const alert = useAlert();

  const { stores } = useSelector((state) => state.stores);
  const { loader } = useSelector((state) => state.loader);
  const [loading, setLoading] = React.useState(false);

  let type = "all";
  // let token = localStorage.getItem("superAdminToken");

  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );

  // console.log(stores, "Sdfsd");

  const columns = [
    { title: "Sr. No", field: "index" },
    {
      title: "Img",
      field: "image",
      render: (item) => (
        <LazyLoadImage
          alt={""}
          effect="blur"
          src={process.env.REACT_APP_IMAGE_URL + item.image}
          height="50"
          width="50"
        />
      ),
    },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    { title: "Address", field: "address" },
    { title: "Phone Number", field: "contactNumber" },
  ];

  const actions = [
    {
      icon: EyeIcon,
      tooltip: "View User",
      onClick: (event, rowData) => dispatch(openModalAction(true, rowData)),
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete User",
      onClick: (event, rowData) => deleteStore(rowData),
    },
  ];

  React.useEffect(() => {
    dispatch(store(adminToken, type));
  }, [dispatch, adminToken, type]);

  const deleteStore = async (rowData) => {
    dispatch(loaderAction(true));

    let Boolean = rowData?.serviceId?.name == "Food" ? true : false;
    let config = {
      headers: { Authorization: `${adminToken}` },
    };

    let body1 = {
      restaurantId: rowData._id,
    };

    let body = {
      groceryId: rowData._id,
    };

    const { data } = await axios.put(
      Boolean ? Api.deleteRestaurant : Api.deleteGrocery,
      Boolean ? body1 : body,
      config
    );

    const { response } = data;
    if (response.status.statusCode === 200) {
      alert.success("Store has been successfully deleted");
      dispatch(store(adminToken));
    } else {
      alert.info(response.status.customMessage);
    }
    dispatch(loaderAction(false));
  };

  return (
    <div
      className={classes.root}
      onClick={() => dispatch(openModalAction(false))}
    >
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />
        <EyeModal />
        <StoreTab btnAction={btnAction} />

        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <MaterialTable
              options={{
                actionsColumnIndex: -1,
              }}
              data={stores && stores}
              columns={columns && columns}
              title=""
              onRowClick={(event, rowData) => console.log(rowData)}
              actions={actions && actions}
            />
          )}
        </Box>
      </main>
    </div>
  );
}
