import axios from "axios";
import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Api } from "../../../helper/ApiList";
import { loaderAction } from "../../../services/actions/loaderAction";
import { signUpUser } from "../../../services/actions/store/user.action";
import { LoginIcon } from "../../../utils/Icons";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import "../../Login/Login.css";
import SelectService from "./Service/SelectService";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Signup() {
  let location = useLocation();
  const dispatch = useDispatch();
  const { loader, message, token, open } = useSelector(
    (store) => store.isStoreSignup
  );

  const history = useHistory();
  const [signUpAction, setSignUpAction] = React.useState(false);
  const alert = useAlert();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alertAction, setAlertAction] = React.useState(false);
  const [countryCode, setCountryCode] = useState("");
  React.useEffect(() => {
    setSignUpAction(location.pathname.substring(1) !== "login" ? true : false);
  }, [location]);

  const handleClick = () => {
    setAlertAction(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertAction(false);
  };

  const handleNumber = (e) => {
    const { value, maxLength } = e.target;
    const message =
      value.length < maxLength - 1 ? handleClick() : handleClose();
    setPhoneNumber(value);
  };

  const handleSubmit = async () => {
    if (!email || !password || !phoneNumber || !countryCode) {
      alert.info("All fields are required");
      dispatch(loaderAction(false));
    } else {
      let body = {
        email,
        password,
        deviceType: "WEB",
        deviceToken: "####",
        countryCode: countryCode,
        contactNumber: parseInt(phoneNumber),
      };

      dispatch(signUpUser(body));
    }
  };
  let options = {
    open: false,
    vertical: "top",
    horizontal: "right",
  };

  return (
    <div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={alertAction}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={options}
          key={options}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Entered number is not valid!
          </Alert>
        </Snackbar>
      </Stack>
      <Container fluid>
        <Row>
          <Col className="login_left_side">
            <div className="logo">
              <LoginIcon />
            </div>
          </Col>
          <Col className="login_right_side">
            <div className="w-75" autoComplete="off">
              <div className="logo">
                <img
                  src={LoginIcon}
                  alt=""
                  srcSet=""
                  className="logo_login_icon"
                />
              </div>
              <p className="login_heading">Sign Up</p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  autoComplete="off"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Country Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="+91"
                      maxLength={5}
                      required={true}
                      name={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {" "}
                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>PhoneNumber</Form.Label>
                    <Form.Control
                      type="phoneNumber"
                      placeholder="PhoneNumber"
                      name="phoneNumber"
                      maxLength={11}
                      required={true}
                      value={phoneNumber}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => handleNumber(e)}
                      //   onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button
                className="login_button"
                type="submit"
                onClick={() => handleSubmit()}
              >
                Next
              </Button>
              {/* </Link> */}
              {signUpAction && (
                <p className="mt-4 back_to_login">
                  <Link to="/restaurant/login" className="sign_up">
                    Back to Login
                  </Link>
                </p>
              )}
            </div>
          </Col>
        </Row>
        <SelectService open={open} />
      </Container>
    </div>
  );
}

export default Signup;
