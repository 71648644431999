import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { Link, useLocation } from "react-router-dom";
import "./Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useStyles } from "../../../utils/useStyles";
import { Button, Col, Form, Row } from "react-bootstrap";
import useFetch from "./../../../hooks/useFetch";

export default function StoreProfile() {
  const classes = useStyles();
  const { isStoreAuthenticated, token } = useSelector(
    (state) => state.isStoreAdmin
  );
  const dispatch = useDispatch();

  let method = "GET";

  const { data } = useFetch(
    Api.showStoreAvailableServiceProfile,
    token,
    method
  );

  const transactionInfo = useFetch(Api.showTransactionInfo, token, method);

  const [email, setEmail] = useState("");

  let editProfileBtn = true;
  let location = useLocation();

  const [heading, setHeading] = React.useState("");

  React.useEffect(() => {
    setHeading(
      location.pathname.substring(1) === ""
        ? "Users"
        : location.pathname.substring(1)
    );
  }, [location]);

  React.useEffect(() => {
    const getProfile = async () => {
      dispatch(loaderAction(true));
      const { data } = await axios({
        method: "get",
        headers: { Authorization: `${token}` },
        url: `${Api.storeGetProfile}`,
      });

      if (data.response) {
        const { status, storeData } = data.response;

        if (status.statusCode === 200) {
          dispatch(loaderAction(false));
          setEmail(storeData[0].email);
        }
        dispatch(loaderAction(false));
      }
      dispatch(loaderAction(false));
    };

    isStoreAuthenticated && getProfile();
  }, [token, isStoreAuthenticated, dispatch]);

  console.log(transactionInfo, "transactionInfo");

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading heading={heading} editProfileBtn={editProfileBtn} />
        <div className="profile_box">
          <div className="profile_flex">
            <div className="profile_img">
              <img src="assets/images/profile_dummy.png" alt="" />
            </div>
            <div className="profile_info">
              {/* <h5>Name</h5>
            <h1>Neeraj</h1> */}
              <h5>Email</h5>
              <h1>{email}</h1>
              {/* <Col xs={12} md={6}> */}
              {/* <div className="profile_info"> */}
              <Link to="/edit-time-slot">
                <Button className="edit_profile_btn show_timing">
                  Show timing
                </Button>
              </Link>
              {/* </div> */}
              {/* </Col> */}
            </div>
          </div>
          <div>
            <hr className="hr_style" />
            <p className="text-order-heading">Store Information</p>
          </div>

          <Row>
            <Col xs={12} md={4}>
              <div className="profile_info mb-4">
                <h5>Store Name</h5>
                <h1>{data && data.length > 0 && data[0].name}</h1>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="profile_info mb-4">
                <h5>Address</h5>
                <h1>{data && data.length > 0 && data[0].address}</h1>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="profile_info mb-4">
                <h5>Created On</h5>
                <h1>
                  {data && data.length > 0 && data[0].createdAt.slice(0, 10)}
                </h1>
              </div>
            </Col>

            <Col xs={12} md={4}>
              <div className="profile_info mb-4">
                <h5>Total Amount</h5>
                <h1>
                  {transactionInfo?.data?.totalAmount
                    ? "$" + transactionInfo?.data?.totalAmount
                    : "$0"}
                </h1>
              </div>
            </Col>

            <Col xs={12} md={4}>
              <div className="profile_info mb-4">
                <h5>Total Received Amount</h5>
                <h1>
                  {" "}
                  {transactionInfo?.data?.receivedAmount
                    ? "$" + transactionInfo?.data?.receivedAmount
                    : "$0"}
                </h1>
              </div>
            </Col>

            <Col xs={12} md={4}>
              <div className="profile_info mb-4">
                <h5>Total Pending Amount</h5>
                <h1>
                  {" "}
                  {transactionInfo?.data?.pendingAmount
                    ? "$" + transactionInfo?.data?.pendingAmount
                    : "$0"}
                </h1>
              </div>
            </Col>
          </Row>
        </div>
      </main>
    </div>
  );
}
