import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../Super-Admin/Sidebar/Sidebar";
import Heading from "../Heading/Heading";
import { useLocation } from "react-router-dom";
import "./Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../helper/ApiList";
import axios from "axios";
import { loaderAction } from "../../services/actions/loaderAction";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Profile() {
  const classes = useStyles();
  const { isStoreAuthenticated, token } = useSelector(
    (state) => state.isStoreAdmin
  );

  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");

  let editProfileBtn = true;
  let location = useLocation();

  const [heading, setHeading] = React.useState("");

  React.useEffect(() => {
    setHeading(
      location.pathname.substring(1) === ""
        ? "Users"
        : location.pathname.substring(1)
    );
  }, [location]);

  React.useEffect(() => {
    const getProfile = async () => {
      dispatch(loaderAction(true));
      const { data } = await axios({
        method: "get",
        headers: { Authorization: `${token}` },
        url: `${Api.storeGetProfile}`,
      });

      if (data.response) {
        const { status, storeData } = data.response;

        if (status.statusCode === 200) {
          dispatch(loaderAction(false));
          setEmail(storeData[0].email);
        }
        dispatch(loaderAction(false));
      }
      dispatch(loaderAction(false));
    };

    isStoreAuthenticated && getProfile();
  }, [token, isStoreAuthenticated, dispatch]);
  console.log(superAdminData, "superAdminData");
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading heading={heading} editProfileBtn={editProfileBtn} />

        <div className="profile">
          <div className="profile_img">
            <img src="assets/images/profile_dummy.png" alt="" />
          </div>
          <div className="profile_info">
            {/* <h5>Name</h5>
            <h1>Neeraj</h1> */}
            <h5>Email</h5>
            <h1>
              {superAdminData && superAdminData?.email
                ? superAdminData?.email
                : superAdminData}
            </h1>
          </div>
        </div>
      </main>
    </div>
  );
}
