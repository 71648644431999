import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import { DeleteIcon } from "../../../utils/Icons";
import Heading from "../../Heading/Heading";

import MaterialTable from "material-table";
import { Api } from "../../../helper/ApiList";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { useStyles } from "../../../utils/useStyles";
import { loaderAction } from "../../../services/actions/loaderAction";
import axios from "axios";
import { useAlert } from "react-alert";

import { useHistory } from "react-router-dom";
export default function AddTaxList() {
  const classes = useStyles();
  const { adminToken } = useSelector((state) => state.isSuperAdmin);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const { data, loading, fetchAgain } = useFetch(
    Api.getTaxesList,
    adminToken,
    "get"
  );

  const deleteTax = async (rowData) => {
    dispatch(loaderAction(true));
    let config = {
      headers: { Authorization: `${adminToken}` },
    };

    const { data } = await axios.put(
      Api.deleteTax,
      {
        _id: rowData._id,
      },
      config
    );

    const { response } = data;
    if (response.status.statusCode === 200) {
      alert.success("Tax has been successfully deleted");
      fetchAgain();
    } else {
      alert.info(response.status.customMessage);
    }
    dispatch(loaderAction(false));
  };

  const columns = [
    { title: "Sr. No", field: "index" },
    { title: "Country", field: "country" },

    { title: "State", field: "state" },
    { title: "Percentage", field: "percentage" },
    { title: "Tax Identifier", field: "taxIdentifier" },
    { title: "createdAt", field: "createdAt" },
  ];

  let tableData =
    data && data.length === 0
      ? []
      : data &&
        data.map((item, index) => {
          return {
            index: index + 1,
            _id: item?._id,
            country: item?.country,
            state: item?.state,
            percentage: item?.percentage,
            taxIdentifier: item?.taxIdentifier,
            createdAt: item?.createdAt.slice(0, 10),
          };
        });

  const actions = [
    // {
    //   icon: EditIcon,
    //   tooltip: "Delete Code",
    //   onClick: (event, rowData) =>
    //     history.push({
    //       pathname: "/add-tax",
    //       search: "?query=" + rowData._id,
    //       state: { detail: "some_value" },
    //     }),
    // },
    {
      icon: DeleteIcon,
      tooltip: "Delete Code",
      onClick: (event, rowData) => deleteTax(rowData),
    },
  ];

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading addTax={true} />

        <div className="mt-2">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
            }}
            data={tableData && tableData}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        </div>
      </main>
    </div>
  );
}
