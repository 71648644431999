import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Category from "./components/Super-Admin/Category/Category";
// import Dashboard from "./components/SuperAdmin/Dashboard/Dashboard";
import Drivers from "./components/Super-Admin/Drivers/Drivers";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import SuperAdminLogin from "./components/Login/SuperAdminLogin";
import RestaurantAdminLogin from "./components/Login/RestaurantAdminLogin";
import Orders from "./components/Super-Admin/Orders/Orders";
import Profile from "./components/Profile/Profile";

import Stores from "./components/Super-Admin/Stores/Stores";
import Users from "./components/Super-Admin/Users/Users";
import Signup from "./components/Restaurant-Admin/Signup/Signup";
import AllOrders from "./components/Restaurant-Admin/Signup/AllOrders/AllOrders";
import Menu from "./components/Restaurant-Admin/Menu/Menu";
import PromoCode from "./components/Restaurant-Admin/PromoCode/PromoCode";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import StoreRequest from "./components/Super-Admin/Stores/StoreRequest";
import OrderDetails from "./components/Super-Admin/OrderDetails/OrderDetails";
import EditProfile from "./components/Profile/EditProfile";
import Promotions from "./components/Restaurant-Admin/PromoCode/Promotions";
import Loader from "./components/Loader/Loader";
import "./App.css";
import {
  PrivateRoute,
  StorePrivateRoute,
} from "./HOC/PrivateRoute/PrivateRoute";
import StoreProfile from "./components/Restaurant-Admin/StoreProfile/StoreProfile";
import EditStoreProfile from "./components/Restaurant-Admin/StoreProfile/EditProfile";
import AddMenus from "./components/Restaurant-Admin/Menu/AddMenus";
import AddRestaurant from "./components/Restaurant-Admin/AddRestaurant/AddRestaurant";
import AddGrocery from "./components/Restaurant-Admin/AddGrocery/AddGrocery";
// import AppleAuth from "./AppleAuth";
import SelectService from "./components/Restaurant-Admin/Signup/Service/SelectService";
import EditMenus from "./components/Restaurant-Admin/Menu/EditMenus";
import AddTaxList from "./components/Super-Admin/AddTax/AddTaxList";
import CourierPrice from "./components/Super-Admin/CourierPrice/CourierPrice";
import Vehicle from "./components/Super-Admin/Vehicle/Vehicle";
import AddNewTax from "./components/Super-Admin/AddTax/AddNewTax";
import AddCourierPrice from "./components/Super-Admin/CourierPrice/AddCourierPrice";
import AddVehicle from "./components/Super-Admin/Vehicle/AddVehicle";
import AddTimeSlots from "./components/Restaurant-Admin/TimeSlots/AddTimeSlots";
import Transaction from "./components/Super-Admin/Transaction/Transaction";
import Restaurants from "./components/Super-Admin/Stores/Restaurants";
import { useState } from "react";
import {
  getAuthentication,
  getEmail,
  getIsAdmin,
  getSession,
} from "./helper/cookies";
import { LOGIN_SUCCESS } from "./services/constants/AdminConstants";
import RedirectRoute from "./HOC/PrivateRoute/RedirectRoute";
import { STORE_LOGIN_SUCCESS } from "./services/constants/StoreConstants";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
function App() {
  const { loader } = useSelector((state) => state.loader);

  const [wait, setWait] = useState(true);
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(
    (state) => state.isSuperAdmin && state.isSuperAdmin
  );

  useEffect(() => {
    const isLoggedIn = () => {
      const token = getAuthentication();
      if (token) {
        let email = getEmail();
        let isExitAdmin = getIsAdmin();

        dispatch({
          type: isExitAdmin === "true" ? LOGIN_SUCCESS : STORE_LOGIN_SUCCESS,
          payload: { accessToken: token, email: email, isAdmin: isExitAdmin },
        });
      } else {
        // getSession
        var sessionToken = getSession();
        if (sessionToken) {
          let email = getEmail();
          let isExitAdmin = getIsAdmin();
          dispatch({
            type: isExitAdmin === "true" ? LOGIN_SUCCESS : STORE_LOGIN_SUCCESS,
            payload: {
              accessToken: sessionToken,
              email: email,
              isAdmin: isExitAdmin,
            },
          });
        }
      }
      setWait(false);
    };
    isLoggedIn();
  }, [dispatch]);

  return (
    <>
      {loader && <Loader />}
      <Router>
        <Switch>
          {/* {!wait ? (
            <PrivateRoute path={"/"} exact component={Users} />
          ) : (
            <StorePrivateRoute path="/all-orders" exact component={AllOrders} />
          )} */}
          <Route exact path="/app/privacy-policy" component={PrivacyPolicy} />

          {!wait && (
            <>
              <RedirectRoute exact path="/login" component={SuperAdminLogin} />

              <RedirectRoute exact path="/" component={SuperAdminLogin} />

              <RedirectRoute
                exact
                path="/restaurant/login"
                component={RestaurantAdminLogin}
              />
              <RedirectRoute exact path="/signup" component={Signup} />
              <RedirectRoute exact path="/service" component={SelectService} />
              <RedirectRoute
                exact
                path="/add-restaurant"
                component={AddRestaurant}
              />
              {/* Super Admin routes */}
              {isAdmin === "true" ? (
                <>
                  <PrivateRoute path={"/"} exact component={Users} />

                  <PrivateRoute exact path="/stores" component={Stores} />
                  <PrivateRoute
                    exact
                    path="/restaurants"
                    component={Restaurants}
                  />
                  <PrivateRoute
                    exact
                    path="/Store-Request"
                    component={StoreRequest}
                  />
                  <PrivateRoute exact path="/drivers" component={Drivers} />
                  <PrivateRoute exact path="/category" component={Category} />
                  <PrivateRoute exact path="/orders" component={Orders} />
                  <PrivateRoute
                    exact
                    path="/orders-detail"
                    component={OrderDetails}
                  />
                  <PrivateRoute exact path="/profile" component={Profile} />
                  <PrivateRoute
                    exact
                    path="/edit-profile"
                    component={EditProfile}
                  />
                  <PrivateRoute exact path="/tax" component={AddTaxList} />
                  <PrivateRoute exact path="/add-tax" component={AddNewTax} />
                  <PrivateRoute
                    exact
                    path="/courier"
                    component={CourierPrice}
                  />
                  <PrivateRoute
                    exact
                    path="/add-courier"
                    component={AddCourierPrice}
                  />
                  <PrivateRoute exact path="/vehicle" component={Vehicle} />
                  <PrivateRoute
                    exact
                    path="/transaction"
                    component={Transaction}
                  />
                  <PrivateRoute
                    exact
                    path="/add-vehicle"
                    component={AddVehicle}
                  />
                </>
              ) : (
                <>
                  {/* Restaurant-admin Routes */}
                  <PrivateRoute
                    path="/all-orders"
                    exact
                    component={AllOrders}
                  />

                  <PrivateRoute
                    exact
                    path="/add-grocery"
                    component={AddGrocery}
                  />

                  <PrivateRoute
                    exact
                    path="/all-orders"
                    component={AllOrders}
                  />
                  <PrivateRoute exact path="/menu" component={Menu} />
                  <PrivateRoute exact path="/add-menu" component={AddMenus} />
                  <PrivateRoute exact path="/edit-menu" component={EditMenus} />
                  <PrivateRoute
                    exact
                    path="/promo-code"
                    component={PromoCode}
                  />
                  <PrivateRoute
                    exact
                    path="/promotions"
                    component={Promotions}
                  />
                  <PrivateRoute
                    exact
                    path="/profile"
                    component={StoreProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/edit-time-slot"
                    component={AddTimeSlots}
                  />
                  <PrivateRoute
                    exact
                    path="/edit-profile"
                    component={EditStoreProfile}
                  />
                  <PrivateRoute
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <PrivateRoute
                    exact
                    path="/orders-detail"
                    component={OrderDetails}
                  />
                </>
              )}
            </>
          )}
          {/* <Route exact path="/apple" component={AppleAuth} /> */}

          {/* <Route component={ErrorPage} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
