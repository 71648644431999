import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
} from "../constants/Constants";

const initialState = {
  userData: null,
  message: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        initialState,
      };

    case USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    case USER_FAIL:
      return {
        initialState,
      };

    default:
      return state;
  }
};

export const deleteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        ...initialState,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
      };
    case DELETE_USER_FAIL:
      return {
        initialState,
      };
    default:
      return state;
  }
};
