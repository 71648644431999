import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const AddPromoCodeBtn = () => {
  return (
    <div className="add_category">
      <Link to="/promotions" className="">
        <Button className="edit_profile_btn">
          <AddCircleOutlineIcon className="mr-2" />
          Add Promo Code
        </Button>
      </Link>
    </div>
  );
};

export default AddPromoCodeBtn;
