import React from "react";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";

import MaterialTable from "material-table";
import { Api } from "../../../helper/ApiList";
import { useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { useStyles } from "../../../utils/useStyles";

import { Button } from "@mui/material";
import PayModal from "./PayModal";
export default function Transaction() {
  const classes = useStyles();
  const { adminToken } = useSelector((state) => state.isSuperAdmin);

  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState(false);

  const { data, fetchAgain } = useFetch(Api.ordersList, adminToken, "get");

  const columns = [
    { title: "Sr. No", field: "index" },
    { title: "Order Id", field: "orderId" },

    { title: "Service Type", field: "serviceType" },
    { title: "Price $", field: "price" },
    { title: "Delivery Team", field: "deliveryTeam" },
    { title: "createdAt", field: "createdAt" },
    {
      title: "Action",
      field: "action",

      render: (item) => (
        <>
          {console.log(item, "itme====")}
          {/* {item.profileImage ? ( */}
          {item?.isPaidToStore ? (
            <span className="text-success">Paid</span>
          ) : item?.isPaidtoDeliveryTeam ? (
            <span className="text-success">Paid</span>
          ) : (
            <Button
              className="pay_btn"
              onClick={() => {
                setOpen(true);
              }}
            >
              Pay
            </Button>
          )}
        </>
      ),
    },
  ];

  /*  */

  let tableData =
    data && data.length === 0
      ? []
      : data &&
        data.map((item, index) => {
          return {
            index: index + 1,
            _id: item?._id,
            orderId: item?.orderId,

            serviceType:
              item?.serviceId?.name === "Food"
                ? item?.restaurantId?.name
                : item?.serviceId?.name === "Courier"
                ? item?.courierId?.name
                : item?.groceryId?.name,
            price: item?.netAmount && "$" + item?.netAmount,
            deliveryTeam:
              item?.deliveryTeamId?.firstName &&
              item?.deliveryTeamId?.firstName +
                " " +
                item?.deliveryTeamId?.lastName,
            createdAt: item?.createdAt?.slice(0, 10),
            action: item,
            rowData: item,
          };
        });

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        <div className="mt-2">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
              paging: true,
              pageSize: 20, // make initial page size
              emptyRowsWhenPaging: false,
              // actionsColumnIndex: -1,
            }}
            data={tableData && tableData}
            columns={columns && columns}
            title=""
            onRowClick={(event, item) => setRowData(item)}
            //   actions={actions && actions}
          />
          <PayModal
            open={open}
            setOpen={setOpen}
            rowData={rowData}
            setRowData={setRowData}
          />
        </div>
      </main>
    </div>
  );
}
