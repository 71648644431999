import React from "react";
import Button from "@mui/material/Button";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function AddMenuModal() {
  const history = useHistory();
  return (
    <div className="add_category">
      <Button
        className="add_category_btn"
        onClick={() => history.push("/add-menu")}
      >
        <AddCircleOutlineIcon className="mr-2" />
        Add Menu
      </Button>
    </div>
  );
}
