import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { openModalAction } from "../../services/actions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EyeModal() {
  const { openModal, modalData } = useSelector((state) => state.openModal);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(openModal);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    setOpen(openModal);
  }, [openModal]);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="row">
            <div className="col-lg-6 text-left">
              <div className="details-modal-left">
                <p>Name</p>
                <p>Email</p>
                <p>Address</p>
                <p>Phone Number</p>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <div className="details-modal-right">
                {modalData && (
                  <>
                    <p>
                      {(modalData.firstName &&
                        modalData.firstName + " " + modalData.lastName) ||
                        modalData.name}
                    </p>
                    <p>{modalData.email}</p>
                    <p>{modalData.address}</p>
                    <p>{modalData.contactNumber}</p>
                  </>
                )}
              </div>
            </div>
          </div>
          <Button
            className="eye_modal_close_btn"
            onClick={() => dispatch(openModalAction(false))}
          >
            close
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
