import { modalContants } from "../constants/Constants";
import { loaderAction } from "./loaderAction";

const openModalAction = (action, rowData) => async (dispatch) => {
  console.log(action, rowData);

  dispatch(loaderAction(true));
  dispatch({
    type: action ? modalContants.OPEN_MODAL : modalContants.CLOSE_MODAL,
    payload: rowData,
    open: action,
  });

  dispatch(loaderAction(false));
};
export { openModalAction };
