import React from "react";
import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div className="privacy_policy">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className=" pt-4 pb-3">
              <p>
                Kador2door and its group companies (collectively, “Kador2door,”
                “we,” “us,” or “our”) are committed to protecting your privacy.
                This Privacy Policy (“Policy”) explains how we collect, use,
                disclose and protect Personal Information (as it is defined
                below) of those that engage with our Services, as defined below,
                (“Users”) and restaurants and/or other businesses utilizing
                Kador2door (“Merchants”). It applies to all Personal Information
                collected by us on.{" "}
                <a
                  href="https://kador2door.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kador2door.com
                </a>{" "}
                and (the “Site”), the Kador2door mobile applications, and any
                other websites or mobile applications that link to this Policy,
                as well as during any written, electronic, and oral
                communications (collectively, the “Services”). Kador2door may
                collect Personal Information to help you place and receive
                orders and to improve the Kador2door service.
              </p>
              <p>
                If you are a Kador2door User, your access to and use of our
                Services are subject to our Terms and Conditions, which define
                some of the terms used throughout this Policy. Please make sure
                that you have carefully read and understand the Terms and
                Conditions before you use our Services. By using our Services,
                you accept the Terms and Conditions, and accept our privacy
                practices described in this Policy. If you do not feel
                comfortable with any part of this Policy or our Terms and
                Conditions, you must not use or access our Services.
              </p>
              <p>
                If you are a Kador2door Merchant, your access to and use of our
                Services are subject to the Merchant Terms of Service or other
                master services agreement entered between you and Kador2door.
                Kador2door uses a network of independent third-party contractors
                who provide delivery services to our Users and Merchants.
                Drivers should refer to the Driver Privacy Policy for
                information on how Kador2door collects and uses Kador2door
                Personal Information.
              </p>
              <p>
                Our Services may change from time to time. As a result, we
                reserve the right to update or modify this Policy at any time
                and from time to time without prior notice. If we make any
                material changes to the way we process your Personal
                Information, we will notify you before such changes are
                effective. If you object to any changes, you must cease using
                our Services. We recommend you review this Policy periodically.
                This Policy became effective on the date indicated at the top of
                this page. Your continued use of our Services after any changes
                or revisions to this Policy indicates your agreement to the
                terms of the revised Policy.
              </p>

              <h4>1. Collection of Personal Information</h4>
              <p>
                When you use our Services, we may collect Personal Information
                about you. The types of Personal Information that we may collect
                about you are:
              </p>
              <u>
                <i>1a. Information You Provide to Us Directly</i>
              </u>
              <p className="pt-3">
                You may provide your Personal Information to us when you use our
                Services, including when you register with Kador2door, search
                within or place orders through our Services, contact us with
                inquiries, engage with our chatbot on the Site or Services,
                respond to surveys, or otherwise access or use our Services.
                This information may include:
              </p>
              <ul>
                <li>
                  {" "}
                  Personal Identifiers and records information – We may collect
                  information such as name, alias, email address, postal or
                  delivery address, account name, phone number, signature, or
                  other similar identifiers.{" "}
                </li>
                <li>
                  {" "}
                  Characteristics of protected classifications under California
                  or federal law – We may collect your age, year or date of
                  birth to enable us, for example, to determine if you are
                  eligible to make purchases of alcohol.
                </li>
                <li>
                  {" "}
                  Biometric information – We may collect face photo, identity
                  document, facial recognition, voice, or other biometric data.
                  Any biometric information will be permanently deleted from the
                  system after it is no longer necessary.
                </li>
                <li>
                  {" "}
                  Commercial Information – When you place an order through our
                  Services, we collect information related to that order. This
                  may include information such as items purchased, special
                  instructions, date and time of order, subtotal, photos that
                  you provide to Kador2door, or other purchasing or consuming
                  histories or tendencies.{" "}
                </li>
                <li>
                  {" "}
                  Payment Information – Payment information collected such as
                  bank name, payment method, or other financial information. We
                  use a payment processor that collects your payment information
                  and processes payments made on or through the Services. We
                  obtain limited information about your payment card from our
                  payment processor, such as the last four digits of your card
                  and the expiration date.
                </li>
              </ul>
              <u>
                <i>
                  1b. Information Received From You as Part of Your
                  Communications
                </i>
              </u>
              <p className="pt-3">
                When you use our Services (for example on our Site), complete
                electronic forms, communicate with Kador2door or contact us, by
                online chat, email, phone or text, we may automatically collect
                and store certain information about you and the activity you
                engaged in, for example: your name and contact information; your
                order details; information that you voluntarily provide to us;
                the nature of your communication; the purpose of the
                interaction, and the action we took in response to your inquiry
                or request.
              </p>
              <u>
                <i>1c. Information Collected Automatically</i>
              </u>
              <p className="pt-3">
                We also may collect and store certain information about you and
                your device(s) automatically when you access or use our Site and
                Services. This information may include:
              </p>
              <ul>
                <li>
                  {" "}
                  Technical Information – We collect technical information
                  associated with your activity on our Site and may include
                  information related to your browser and operating system, IP
                  address (the Internet address of your computer), unique device
                  identifiers, personal and online identifiers, and other
                  information such as your device type and version of the app
                  that is in use.
                </li>
                <li>
                  {" "}
                  Site Usage Information – This may include the webpage that you
                  were visiting before accessing our Site or mobile application,
                  the pages or features of our Site or mobile application that
                  you browsed to inform us which part of our Site, app and
                  Services you visit and how much time you spend there.{" "}
                </li>
                <li>
                  {" "}
                  Site Preferences – We collect information about your
                  preferences to make your use of the Site more productive
                  through the use of cookies.
                </li>
              </ul>
              <u>
                <i>1d. Location Information</i>
              </u>
              <p className="pt-3">
                When you use the Services, we may collect and store general
                location information (such as IP address). We may also collect
                route information and delivery pick-up or drop-off information.
                If you permit the Services to access location services through
                the permission system used by your mobile operating system
                (“Platform”) or browser, we may also collect and store the
                precise location of your device when the Kador2door app is
                running in the foreground or background of your device. This
                information may be used to help you select your delivery
                address, to provide more accurate deliveries of your orders, to
                provide recommendations to you, and to enhance your user
                experience and improve the services. You can choose whether to
                enable the location tracking feature through the settings on
                your device or Platform or when prompted by the Kador2door
                mobile app. If you choose to disable the location feature, the
                Driver will not receive precise location information from your
                device, which may compromise the accuracy of deliveries in some
                situations, for instance, if you are located in a large area,
                such as a park.
              </p>
              <u>
                <i>
                  1e. Information Related to the Referral Program or Gift Option
                </i>
              </u>
              <p className="pt-3">
                Kador2door allows users to send gifts using the Services and
                invite friends and family to sign up for our Services. Sometimes
                we offer a promotional value for every friend or family member
                who signs up and places an order through your referral link. To
                help you do this, we may request you to grant us access to your
                mobile device address book. You can then direct us to send
                referral emails to contacts you select in your address book. By
                sending a referral or gift, you consent to Kador2door sending
                your public profile image to the contacts you select to receive
                a referral link or gift. By choosing to invite a friend or
                sending a gift to a recipient, you represent that you have
                received the friend’s and/or recipient’s consent to provide his
                or her name, mailing address, email address or other information
                with us. Kador2door will use that information to send the
                requested gift or an email inviting him or her to visit the site
                and will store that information to track the success of the
                referral program and may send marketing emails. You can also
                send an SMS with a referral link to your contacts. If you use
                this option, you understand that Kador2door does not control the
                timing, content, or recipients of these SMS messages.
              </p>
              <p>
                Users may not send, publish, or share any false, misleading, or
                deceptive communications in connection with participation in the
                Referral Program. Such actions may violate various applicable
                laws. Kador2door users may not send any referral emails, SMS
                messages, or other communications to children under the age of
                13.
              </p>
              <p>
                If you are referred to Kador2door through one of our referral
                programs, or if someone attempts to refer you, we may share your
                name, whether you have already signed up for Kador2door, whether
                you are a new customer, and whether you have placed a qualifying
                order with Kador2door with the person(s) who referred you.
              </p>
              <u>
                <i>
                  1f. Personal Information from Publicly Available Sources and
                  Third Parties
                </i>
              </u>
              <p className="pt-3">
                We may collect Personal Information about you from publicly
                available sources, social network providers, marketing partners,
                and third parties. This may include:
              </p>
              <ul>
                <li>
                  {" "}
                  Social Network Information – We may receive information about
                  you from other services. For example, we receive certain
                  information from Facebook when you use their service to login
                  to Kador2door.
                </li>
                <li>
                  {" "}
                  Demographic Information – This characteristic information
                  could include information such as income, marital status,
                  spending habits, and other information.
                </li>
              </ul>
              <h4>2. Use of Cookies and Other Tracking Technologies</h4>
              <p>
                We use cookies, web beacons, pixels, session replay/screen
                capture, and similar technologies to collect information and
                personalize your experience with our Services. When we use
                session replay technology, we may collect on a real-time basis
                information on how you use and navigate the Services. This may
                include mouse movements and how you scroll through the Services,
                but we do not collect keystroke data. Additional information on
                other technologies we may use is set forth below.
              </p>
              <u>
                <i>2a. Cookies</i>
              </u>
              <p className="pt-3">
                Cookies are small web files that a site or its provider
                transfers to your device’s hard drive through your web browser
                that enables the site’s or provider’s system to recognize your
                browser and remember certain information.
              </p>
              <u>
                <i>How We Use Cookies </i>
              </u>
              <span>
                – We use first-party and third-party cookies for the following
                purposes:
              </span>
              <ul>
                <li> to make our Services function properly;</li>
                <li> to improve our Services;</li>
                <li>
                  {" "}
                  to recognize you when you return to our Site and to remember
                  information you have already provided, such as items already
                  in your order cart;
                </li>
                <li>
                  {" "}
                  to collect information about your activities over time and
                  across third party websites or other online services in order
                  to deliver content and advertising tailored to your interests;
                  and
                </li>
                <li>
                  {" "}
                  to provide a secure browsing experience during your use of our
                  Services.
                </li>
              </ul>
              <u>
                <i>How to Manage Cookies </i>
              </u>
              <span>
                – You may disable the use of cookies by modifying your browser
                settings. If you choose to disable cookies you may not be able
                to fully experience the interactive features of our Services.
                For more information on cookies and how to disable cookies visit{" "}
                <a
                  href="https://www.ftc.gov/ftc/cookies.shtml"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.ftc.gov/ftc/cookies.shtm
                </a>{" "}
                or
                <a
                  href="https://www.usa.gov/optout_instructions.shtml"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.usa.gov/optout_instructions.shtml
                </a>
                .
              </span>
              <u>
                <i>2b. Web Beacons</i>
              </u>
              <p className="pt-3">
                {" "}
                Web beacons, also known as web bugs, pixel tags or clear GIFs,
                are tiny graphics with a unique identifier that may be included
                on our Site to deliver or communicate with cookies, in order to
                track and measure the performance of our Site and Services,
                monitor how many web visitors we have, and to monitor the
                effectiveness of our advertising. Unlike cookies, which are
                stored on the user’s hard drive, web beacons are typically
                embedded invisibly on webpages (or in an email).
              </p>
              <u>
                <i>2c. Online Analytics and Advertising Technologies</i>
              </u>
              <p className="pt-3">
                We and our third-party vendors may use automated technologies
                (including cookie identifiers on our Site), along with other
                collected information, to tailor ads or deliver content when you
                are on our Services or on other devices, apps or websites.
              </p>
              <u>
                <i>2d. Targeted Advertising</i>
              </u>
              <p className="pt-3">
                We (or our service providers) may use the information we
                collect, for instance, IP addresses and unique mobile device
                identifiers, to locate or try to locate the same unique users
                across multiple browsers or devices (such as smartphones,
                tablets, or computers), or work with providers that do this, in
                order to better tailor content and features and provide you with
                a seamless experience across devices. If you wish to opt out of
                such cross device tracking for purposes of interest-based
                advertising, you may do so through your device settings. We may
                also use cross-device tracking to help identify our users and
                serve advertising. This type of advertising is often called
                “interest-based” or “personalized” advertising—and when it
                occurs on mobile apps, “cross-app” advertising.
              </p>
              <p>
                You can learn more about interest-based advertising and how to
                opt-out of receiving tailored advertising by visiting (i) the
                Network Advertising Initiative’s Consumer Opt-Out link or (ii)
                the Digital Advertising Alliance’s Consumer Opt-Out link. To opt
                out of Google Analytics for display advertising or customize
                Google display network ads, you can visit the Google Ads
                Settings page. California users can also opt-out of receiving
                interest-based advertising based on your browser by opting out
                using the Do Not Sell link on the
                <a
                  href="https://kador2door.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kador2door.com
                </a>{" "}
                homepage. That opt-out is only effective on the browser level
                and on{" "}
                <a
                  href="https://kador2door.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kador2door.com
                </a>{" "}
                . If you clear your cookies on this browser or use a different
                browser, you will need to renew your request.{" "}
              </p>
              <p>
                Please note that even if you opt-out, you may still receive
                advertisements from us. However, in that case, the advertising
                will not be tailored to your interests. Also, we do not control
                any of the above opt-out links or whether any particular company
                chooses to participate in these opt-out programs.
              </p>
              <u>
                <i>2e. Mobile Applications</i>
              </u>
              <p className="pt-3">
                Depending on your permissions, we may receive your Personal
                Information from your Internet service and mobile device
                providers. Users of mobile devices who do not want to receive
                interest-based advertising may opt-out in several ways. Learn
                more about your choices for mobile devices by visiting
                <a
                  href="https://kador2door.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Kador2door.com
                </a>{" "}
                . Each operating system, (iOS for Apple phones, Android for
                Android devices, and Windows for Microsoft devices) provides its
                own instructions on how to prevent the delivery of tailored
                in-application advertisements. You should view your device or
                system “Settings” to determine how you can opt out of use of
                your device ID for “cross-app” personalized advertising.
              </p>
              <h4>3. Use of Your Personal Information</h4>
              <p>
                We may use the information we collect about you for various
                purposes.
              </p>
              <u>
                <i>3a. To Provide You with the Services</i>
              </u>
              <p className="pt-3">
                We use your information to provide you the Services. For
                example, we use credit card information to complete a
                transaction or an address to allow the Driver to fulfill your
                delivery (if you are a User), or your name and phone number to
                set up your business on the Services (if you are a Merchant). If
                the applicable information is to be provided or Service is to be
                performed by a third party, then we will disclose the applicable
                Personal Information to the third party providing the
                information for performing the applicable Services. Your
                information may be available or provided to third-party or
                service providers that are contractually obligated to protect
                your information as disclosed in this Policy. Payment
                information may be shared with your bank to validate your
                payment card.
              </p>
              <u>
                <i>3b. To Maintain, Improve, and Personalize the Services</i>
              </u>
              <p className="pt-3">
                We use your information for our everyday business operations
                such as auditing, administration of the Services, forum
                management, fulfillment, and analytics. Your information may
                also be used to improve the content and/or functionality of the
                Services. We may use your information to help us enhance
                offerings for Kador2door and our Merchants, which may include
                improving marketing efforts for our large order delivering
                service, Kador2door Drive. We also use your information to
                personalize your experience. For example, we may personalize the
                content and features you see when visiting our Services. In
                addition, we may personalize advertisements, marketing
                communications, and recommendations to make them more tailored
                to your interests.
              </p>
              <u>
                <i>3c. To Communicate with You</i>
              </u>
              <p className="pt-3">
                We use your information to communicate with you. For example, we
                may send you text messages or other notifications about the
                status of your orders and deliveries. We may also contact you
                with promotional offerings or other communications that may be
                of interest to you. If we send you marketing emails or texts
                about services that may interest you, each email or text will
                contain instructions permitting you to “opt-out” of receiving
                future email or text marketing communications.
              </p>
              <p>
                In the event you contact us, we use certain information to
                respond to your questions and assist you with any issues that
                may arise in your use of the Services. If you send direct
                messages, such as chat or text messages to a Driver, using the
                telephone number for that Driver available on the Services, we
                may use a third-party service provider to monitor and analyze
                these messages. We monitor these messages for fraud prevention,
                to ensure appropriate charging of fees, to enforce our Terms and
                Conditions, and for quality and training purposes. As part of
                this process, Kador2door and its service provider will receive
                in real time and store data about your direct messages, such as
                chat or text messages, including the date and time of the text
                message, your phone number, and the message. If you do not agree
                with the monitoring of direct messages you send to Drivers,
                please do not send messages to Drivers.
              </p>
              <u>
                <i>3d. For Account and Network Security Purposes</i>
              </u>
              <p className="pt-3">
                We care about keeping you secure and safe while using our
                Services. Keeping you safe requires us to process your Personal
                Information, such as your device information, activity
                information and other relevant information. We use such
                information to combat spam, malware, malicious activity or
                security risks; improve and enforce our security measures; and
                to monitor and verify your identity so that unauthorized users
                do not gain access to your information.
              </p>
              <u>
                <i>3e. To Maintain Legal and Regulatory Compliance</i>
              </u>
              <p className="pt-3">
                Our Services are subject to certain laws and regulations which
                may require us to process your Personal Information. For
                example, we process your Personal Information to pay our taxes,
                to fulfill our business obligations, ensure compliance with
                employment and recruitment laws or as necessary to manage risk
                as required under applicable law.
              </p>
              <u>
                <i>
                  3f. To Enforce Compliance with Our Agreements and Policies
                </i>
              </u>
              <p className="pt-3">
                When you access or use our Services, you are bound to our Terms
                and Conditions (if you are a User), the Merchant Terms of
                Service or other master services agreement entered into between
                you and Kador2door (if you are a Merchant), and this Policy. To
                ensure you comply with them, we process your Personal
                Information by actively monitoring, investigating, preventing
                and mitigating any alleged or actual prohibited, illicit or
                illegal activities on our Services. We also process your
                Personal Information to: investigate, prevent or mitigate
                violations of our internal terms, agreements or policies;
                enforce our agreements with third parties and business partners.
              </p>
              <u>
                <i>3g. To Protect You, Others, and Our Business</i>
              </u>
              <p className="pt-3">
                We use your information to protect you, others, and our
                business, including, without limitation, using information for
                fraud prevention, for enforcement of our Terms of Service, to
                comply with the law, and to protect against security risks.
              </p>
              <u>
                <i>3h. For Our Business Purposes</i>
              </u>
              <p className="pt-3">
                We may use your information for any other purpose disclosed to
                you at the time we collect the information, or otherwise with
                your consent.
              </p>
              <h4>4. Provision of Personal Information to Third Parties</h4>
              <p>
                We may provide your Personal Information to third parties as
                described below.
              </p>
              <u>
                <i>4a. Service Providers or Third Parties</i>
              </u>
              <p className="pt-3">
                We may provide your information to our service providers or
                third parties to help provide services to you for certain
                business purposes. This information is provided in order for
                them to provide us services such as payment processing,
                advertising services, marketing partners, web analytics, data
                processing, IT services, customer support and other services.
                These service providers or third parties may have access to your
                Personal Information to perform services on our behalf.
              </p>
              <u>
                <i>4b. Drivers, Merchants or other users</i>
              </u>
              <p className="pt-3">
                To help provide you with a high-quality delivery we also may
                provide your information, including your Personal Information
                and order details, to the Drivers who complete your requested
                deliveries. Similarly, we may provide information to Merchants
                to facilitate deliveries such as your first name and last
                initial and order information related to your orders with
                Merchants. We may also share information that you provide to
                Kador2door with Merchants as part of a Merchant Loyalty Program
                when you connect your Kador2door account with the applicable
                Merchant program.
              </p>
              <u>
                <i>4c. Group companies</i>
              </u>
              <p className="pt-3">
                We may provide your information to our group companies (entities
                that control or are under the control of Kador2door.) for
                business purposes. We will require these entities to comply with
                the terms of this Policy with regard to their use of your
                information.
              </p>
              <p>
                Service provided by Kador2door, and our group companies may
                share certain functionalities such as a uniform login experience
                where users of both applications will need to use a single
                username and password.
              </p>
              <u>
                <i>4d. Social Media</i>
              </u>
              <p className="pt-3">
                When you link a Social Network account to Kador2door or log into
                our Services using your Social Network account, we may provide
                your information to the operator of the Social Network to
                facilitate or enhance delivery of that Social Network or other
                services to you. Please see “8. Social Media Usage” below for
                more details.
              </p>
              <u>
                <i>4e. When Required or Permitted by Law</i>
              </u>
              <p className="pt-3">
                We recognize that information related to your orders could
                contain private information. However, we may be required to
                provide your information to third parties including law
                enforcement agencies when required to protect and defend our
                legal rights, protect the safety and security of users of our
                Services, prevent fraud, comply with the law, respond to legal
                process, or a request for cooperation by a government entity,
                and the like.
              </p>
              <u>
                <i>4f. Corporate Transactions</i>
              </u>
              <p className="pt-3">
                In the event of a business transfer, merger and other
                reorganization, dissolution, or similar event we may provide
                your information to one or more third parties as part of that
                transaction, including during the negotiation of a transaction.
              </p>
              <u>
                <i>4f. With Your Consent</i>
              </u>
              <p className="pt-3">
                We also may provide your information to third parties with your
                consent or at your direction.
              </p>
              <h4>5. Security</h4>
              <p>
                Kador2door has implemented administrative, organizational,
                technical, and physical security controls that are designed to
                safeguard Personal Information. However, no online activity is
                ever fully secure or error-free. While we strive to protect your
                information, we cannot guarantee that your Personal Information
                is absolutely secure. Please keep this in mind when disclosing
                any information to Kador2door.
              </p>
              <p>
                Please recognize that protecting your Personal Information is
                also your responsibility. We urge you to take every precaution
                to protect your information when you are on the Internet, or
                when you communicate with us and with others through the
                Internet. Change your passwords often, use a combination of
                letters and numbers, and make sure you use a secure browser. If
                you have reason to believe that your interaction with us is no
                longer secure (for example, if you feel that the security of
                your account might have been compromised), or if you suspect
                someone else is using your account, please let us know
                immediately by contacting us as indicated in the Contact Us
                section below.
              </p>
              <h4>6. Data Retention</h4>
              <p>
                We will retain your Personal Information for as long as your
                account is active or as needed to provide you services and to
                maintain a record of your transactions for financial reporting
                purposes. We will also retain and use your Personal Information
                as necessary to comply with our legal obligations, resolve
                disputes, and enforce our agreements.
              </p>
              <h4>7. Third-Party Sites</h4>
              <p>
                Some of the functionality may be provided by third parties and
                the Services may link to other third-party websites that are not
                controlled by Kador2door. For example, we may use services like
                Braintree for payment processing. These third parties are not
                under our control and Kador2door is not responsible for their
                privacy policies or practices. If you provide any Personal
                Information to any third party or through any such third-party
                website, we recommend that you familiarize yourself with the
                privacy policies and practices of that third party.
              </p>
              <h4>8. Social Media Usage</h4>
              <p>
                You may choose to enable or log in to our Services via various
                online services, including social networking services like
                Facebook and Google. Our Services also may enable you to access
                social networking services such as Facebook, Twitter, or
                Instagram (collectively, “Social Network”) directly or
                indirectly through our Services.
              </p>
              <p>
                When you link a Social Network account to Kador2door or log into
                our Services using your Social Network account, we may collect
                relevant Personal Information necessary to enable our Services
                to access that Social Network and your information contained
                within that Social Network. We also may share your information
                with the operator of that Social Network to facilitate or
                enhance delivery of that Social Network or other services to
                you. A Social Network may provide us with access to certain
                information that you have provided to them, and we will use,
                store, and disclose such information in accordance with this
                Policy. Additionally, a Social Network may collect Personal
                Information and information about your use of our Site or
                Services automatically. The manner in which a Social Network
                collects, uses, stores, and discloses your information is
                governed by the policies of such third parties and Kador2door
                shall have no liability or responsibility for the privacy
                practices or other actions of any Social Network that may be
                enabled within our Services.
              </p>
              <p>
                You may also have the option of posting your activities on our
                Services and other content to a Social Network when you access
                content through our Services. For example, you may post to
                Facebook that you placed an order with Kador2door. Keep in mind
                that your usage of Social Network features is governed by
                applicable Social Networks and not by Kador2door and may be
                visible or accessible to the public.
              </p>
              <h4>9. Children’s Information</h4>
              <p>
                Our Services are not intended for children under 13 years of
                age, and we do not knowingly collect Personal Information from
                children under the age of 13. If you are a parent or guardian of
                a child under the age of 13 and believe he or she has disclosed
                Personal Information to us, please contact us at{" "}
                <a href="mailto:privacy@doordash.com">kador2door.com</a> . A
                parent or guardian of a child under age 13 may review and
                request deletion of the child’s Personal Information.
              </p>
              <h4>10. Your Choices</h4>
              <p>
                We provide users and their authorized agents with the ability to
                access and delete Personal Information. In order to exercise
                these rights, you must login to your account to confirm your
                identity, which helps us ensure that Personal Information is
                only made accessible to appropriate parties. Users will not
                receive discriminatory treatment for exercising their rights and
                can return to the service after deleting their information.{" "}
              </p>
              <u>
                <i>10a. Your Rights</i>
              </u>
              <p className="pt-3">
                This section lists the privacy-related rights (“Rights”) we
                extend to Kador2door users. Your rights are not absolute and are
                subject to certain exceptions. For instance, we cannot disclose
                specific pieces of Personal Information if the disclosure would
                create a substantial, articulable, and unreasonable risk to the
                security of the Personal Information, your account with us or
                the security of our systems.
              </p>
              <ul>
                <li>
                  {" "}
                  Right to Know – You have the right to request that we disclose
                  the Personal Information we retain about you.
                </li>
                <li>
                  {" "}
                  Right to Know – You have the right to request that we disclose
                  the Personal Information we retain about you.
                </li>
                <li>
                  {" "}
                  Right to Rectification – If you are a registered Kador2door
                  user you may modify certain of your Personal Information by
                  logging into our Site using your username and password and
                  visiting the “Account” page. Registered Kador2door users may
                  also access the “Account” page to manage their subscriptions.
                </li>
                <li>
                  {" "}
                  Right to Opt-Out of the Sale of Your Personal Information –
                  You have the right to opt-out of having your Personal
                  Information sold. The California Privacy Rights Act (“CPRA”)
                  amends the California Consumer Privacy Act to broadly define
                  “sale” in a way that may include allowing third parties to
                  receive certain information such as cookie identifiers, IP
                  addresses and/or browsing behavior to add to a profile about
                  your device, browser or you. Such profiles may enable delivery
                  of interest-based advertising by such third parties within
                  their platform or on other sites. Depending on how you use the
                  Services, we may share the following categories of information
                  for such interest-based advertising which may be considered a
                  sale (as defined by the CPRA): (1) device information and
                  identifiers, such as IP address and unique advertising
                  identifiers and cookies; and (2) connection and usage
                  information, such as browsing history or app usage. Although
                  this obligation of the CPRA does not become effective until
                  January 1, 2023, you may opt out of such tracking technologies
                  by following the instructions in Section 2 above.
                </li>
                <li>
                  {" "}
                  Right to Non-Discrimination –Kador2door will not discriminate
                  against those who exercise their Rights. Specifically, if you
                  exercise your Rights, we will not deny providing you with
                  goods or Services, charge you different prices or rates for
                  goods or Services or provide you a different level or quality
                  of goods or Services.
                </li>
              </ul>
              <u>
                <i>10b. Asserting Your Rights</i>
              </u>
              <p className="pt-3">
                You may exercise your Rights by clicking here for Kador2door. To
                verify your identity, you must log-in to your account prior to
                accessing or deleting your information. If you have Kador2door
                user account, then the information associated with both accounts
                will be deleted to the extent we can identify that the accounts
                are owned by the same user.{" "}
              </p>
              <p>
                If you do not have a Kador2door account, you may exercise your
                right to know or your right to deletion by emailing your request
                to us at{" "}
                <a href="mailto:privacy@doordash.com">kador2door.com</a> from
                the email address that you believe is associated with
                Kador2door, with the subject line “Non-user Data Request” or
                calling us at 608-474-0591. For safety and to otherwise prevent
                fraud, we may ask that you provide us with certain personal
                information that’s reasonably necessary for us to confirm your
                identity.
              </p>
              <p>
                You may also designate an authorized agent to make a request to
                know or a request to delete. In order to be able to act,
                authorized agents have to submit written proof that they are
                authorized to act on your behalf or have a power of attorney. We
                may deny requests from authorized agents who do not submit proof
                that they have been authorized by you to act on their behalf.
              </p>
              <h4>11. California Residents</h4>
              <p>
                Pursuant to the California Consumer Privacy Act of 2018, below
                is a summary of the Personal Information we collected from
                consumers, the reason we collected the Personal Information,
                where we obtained the Personal Information we collected, and the
                third parties with whom we may share consumer Personal
                Information. The section references relate to the sections above
                in this Policy.
              </p>
              <p>
                As described further in our Privacy Policy, in the preceding
                twelve months, we or our service providers may have collected
                the below categories of personal information for business or
                commercial purposes:
              </p>
              <ul>
                <li>
                  {" "}
                  Identifiers such as a name, address, unique personal
                  identifier, email, phone number;
                </li>
                <li> User records such as signature;</li>
                <li>
                  {" "}
                  Commercial information such as records of products or services
                  purchased, obtained, or considered;
                </li>
                <li>
                  {" "}
                  Internet or other electronic network activity information,
                  including browsing history and search history;
                </li>
                <li>
                  {" "}
                  Geolocation data that is sufficient to identify a precise
                  physical location;
                </li>
                <li>
                  {" "}
                  Sensory data, such as audio, electronic, visual, or other
                  similar information;
                </li>
                <li> Professional or employment-related information;</li>
                <li> Education information; and</li>
                <li>
                  {" "}
                  Inferences about preferences, characteristics, psychological
                  trends, predispositions, behavior, attitudes, intelligence,
                  abilities, and aptitudes.
                </li>
              </ul>
              <p>
                We collect the categories of personal information identified
                above from the following sources: (1) directly from you; (2)
                through your use of the Services; (3) affiliates; and (4) third
                parties such as information received from our referral program,
                social networking services, publicly available sources, and
                marketing partners.
              </p>
              <p>
                As explained above, we collect the categories of personal
                information identified above for the following business and
                commercial purposes:
              </p>
              <ul>
                <li> To provide you with the Services; </li>
                <li> To maintain, improve, and personalize the Services;</li>
                <li> To communicate with you;</li>
                <li> For account and network security purposes;</li>
                <li> To maintain legal and regulatory compliance;</li>
                <li>
                  {" "}
                  To enforce compliance with our Terms and Conditions and this
                  Policy;
                </li>
                <li> To protect you, others, and our business; and</li>
                <li>
                  {" "}
                  For our business purposes disclosed to you at the time we
                  collect or receive the information, or otherwise with your
                  consent.
                </li>
              </ul>
              <p>
                We describe our information sharing practices in the Privacy
                Policy above. In the previous twelve months, we may have shared
                certain categories of personal information with third parties,
                as that term is defined in the California Consumer Privacy Act,
                for business purposes. We may have shared your personal
                information with a merchant if you expressly directed us to do
                so. The information shared may include the following categories
                of personal information: (1) identifiers, such as a name and
                email address; and (2) commercial information, such as your
                history of orders from that merchant. We may have also enabled
                third parties that provide paid analytics and advertising
                services to us to place tracking technologies on our website.
                These providers may have been able to access the following
                categories of personal information: (1) device information and
                identifiers, such as IP address and unique advertising
                identifiers and cookies; and (2) connection and usage
                information, such as browsing history or app usage. You can opt
                out of such tracking technologies by following the instructions
                in Section 2 above.
              </p>
              <p>
                Do Not Track Disclosure. Kador2door does not have a mechanism in
                place for responding to browser “do not track” signals or other
                similar mechanisms used to limit collection of information for
                use in Online Behavioral Advertising.
              </p>
              <h4>12. Nevada Residents</h4>
              <p>
                Under Nevada law, certain Nevada consumers may opt out of the
                sale of “personally identifiable information” for monetary
                consideration by an operator to a recipient for that recipient
                to license or sell such information to additional persons.
                “Personally identifiable information” includes first and last
                name, address, email address, phone number, Social Security
                Number, or an identifier that allows a specific person to be
                contacted either physically or online. We do not engage in such
                activity; however, if you are a Nevada resident who has
                purchased or leased goods or services from us, you may submit a
                request to opt out of any potential future sales under Nevada
                law by contacting{" "}
                <a href="mailto:privacy@doordash.com">kador2door.com</a> .
                Please note we will take reasonable steps to verify your
                identity and the authenticity of the request. Once verified, we
                will maintain your request in the event our practices change.
              </p>
              <h5>13. International Users</h5>
              <p>
                Regardless of where you use our Services, the information
                collected as part of that use will be transferred to and
                maintained on servers located in the United States. By using our
                Services, you consent to this collection, transfer, storage, and
                processing of information to and in the United States.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
