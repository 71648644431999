import React from "react";

import Moment from "react-moment";
import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { EditIcon } from "../../../utils/Icons";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { order } from "../../../services/actions/orderAction";
import { useStyles } from "../../../utils/useStyles";
import moment from "moment";

export default function Orders() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { adminToken } = useSelector((state) => state.isSuperAdmin);

  const { orderList } = useSelector((state) => state.orderList);
  console.log("orderList: ", orderList);

  // let token = localStorage.getItem("superAdminToken");
  React.useEffect(() => {
    dispatch(order(adminToken));
  }, [adminToken, dispatch]);

  const columns = [
    { title: "Sr. No", field: "index" },
    { title: "Order No", field: "orderId" },

    { title: "Delivery Location", field: "deliveryLocation" },
    { title: "Price $", field: "price" },
    {
      title: "Delivery Date & Time",
      field: "deliveryDateAndTime",
      render: (item) => (
        <span>
        {item?.deliveryDateAndTime?moment(item?.deliveryDateAndTime).fromNow():""} 
        </span>
      ),
    },
    { title: "Service Type", field: "serviceType" },
    { title: "Store Name", field: "storeName" },
    { title: "Store Address", field: "storeAddress" },

    {
      title: "Status",
      field: "status",
      render: (item) => (
        <span
          className={
            item.status === "PICKEDUP"
              ? "pickedup"
              : item.status === "CONFIRMED"
              ? "confirmed"
              : "delivered"
          }
        >
          {item.status}
        </span>
      ),
    },
  ];

  const actions = [
    // {
    //   icon: EditIcon,
    //   tooltip: "Edit Order",
    //   onClick: (event, rowData) => {
    //     dispatch(singleOrderDetails(rowData.data));
    //     history.push("/orders-detail");
    //   },
    // },
  ];

  let data =
    orderList && orderList.length === 0
      ? []
      : orderList &&
        orderList.map((item, index) => {
          return {
            index: index + 1,
            orderId: item?.orderId,
            deliveryLocation: item?.userId?.address,
            price: item?.netAmount,
            deliveryDateAndTime: item?.orderConfirmedAt,
            status: item?.orderStatus,
            _id: item?._id,
            serviceType: item?.serviceId?.name,
            storeName: item?.restaurantId?.name,
            storeAddress: item?.restaurantId?.address,
            data: item,
          };
        });

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        <div className="mt-2 table-set">
          <MaterialTable
            options={{
              actionsColumnIndex: -1,
            }}
            data={data && data}
            columns={columns && columns}
            title=""
            onRowClick={(event, rowData) => console.log(rowData)}
            actions={actions && actions}
          />
        </div>
      </main>
    </div>
  );
}
