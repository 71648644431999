import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Button, Container } from "react-bootstrap";

import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { useStyles } from "../../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { Api } from "../../../helper/ApiList";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loaderAction";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { editRestaurantAndGroceryTimeSlots } from "../../../helper/Api";
export default function AddTimeSlots() {
  const classes = useStyles();
  const { token } = useSelector((store) => store.isStoreAdmin);
  let method = "GET";

  const { data, loading, error, fetchAgain, type } = useFetch(
    Api.showStoreAvailableServiceProfile,
    token,
    method
  );

  const dispatch = useDispatch();

  const alert = useAlert();

  const history = useHistory();

  const [formData, setFormData] = useState([]);

  let date = [
    "Monday",

    "Tuesday",

    "Wednesday",

    "Thursday",

    "Friday",

    "Saturday",

    "Sunday",
  ];

  const handleAddSlot = () => {
    let count = formData.length;

    if (formData.length == 7) {
      alert.info("Max slot is 7 days only");
      return false;
    } else {
      let data = {
        day: date[count],
        dayNumber: count,
        openTime: "",
        closeTime: "",
      };
      setFormData((pre) => [...pre, data]);
    }
  };

  const handleRemoveSlot = (day) => {
    let data = formData.filter((item) => item.day !== day);
    setFormData(data);
  };

  const handleForm = (e, index) => {
    console.log(index);
    e.preventDefault();
    const { name, value } = e.target;
    let data = formData;
    data[index][name] = value;
    setFormData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      alert.error("Session expired, please login again");
      setTimeout(() => {
        history.push("/restaurant/login");
      }, 900);
      return false;
    }

    if (formData.length === 0) {
      alert.error("Please add a time slots");
      return false;
    }

    if (formData[0].openTime == "" || formData[0].closeTime == "") {
      alert.error("All fields are required");
      return false;
    }

    dispatch(loaderAction(true));

    let body = {
      serviceType: type.toLowerCase(),
      timeSlots: formData,
    };

    const data = await editRestaurantAndGroceryTimeSlots(
      Api.editTimeSlots,
      body,
      token
    );

    if (data?.data?.response.status.statusCode === 200) {
      alert.success("Time slots been updated successfully");
      setTimeout(() => {
        history.push("/Profile");
      }, 900);
    } else {
      alert.error(
        data?.response.data.message ||
          data?.data?.response.data.customMessage ||
          data?.response.status.customMessage
      );
    }
    dispatch(loaderAction(false));
  };

  useEffect(() => {
    let timeSlots = data && data.length > 0 ? data[0] : [];

    if (timeSlots) {
      setFormData(timeSlots.timeSlots);
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        <Row className="pt-5 edit_profile_form">
          <Col md={6}>
            <Form.Group>
              <Form.Label onClick={() => handleAddSlot()}>
                Add Time Slots{" "}
                <AddCircleOutlineIcon className="mr-2 add_slots" />
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row className="edit_profile_form">
          {formData &&
            formData.map((date, index) => (
              <Col md={6} key={index}>
                <Form.Group className="mb-3">
                  <Form.Label>{date?.day} </Form.Label>{" "}
                  <HighlightOffIcon
                    className="close_icon"
                    onClick={() => handleRemoveSlot(date?.day)}
                  />
                  <Row className="edit_profile_form">
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Open Time</Form.Label>

                        <Form.Control
                          placeholder=""
                          name="openTime"
                          type="time"
                          defaultValue={date.openTime}
                          onChange={(e) => handleForm(e, index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Close Time</Form.Label>

                        <Form.Control
                          placeholder=""
                          name="closeTime"
                          type="time"
                          defaultValue={date.closeTime}
                          onChange={(e) => handleForm(e, index)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            ))}
        </Row>

        <Button
          className="edit_profile_btn p-4 pt-2 pb-2 "
          onClick={(e) => handleSubmit(e)}
        >
          {" "}
          Submit
        </Button>
      </main>
    </div>
  );
}
