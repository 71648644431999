import axios from "axios";
import { Api } from "../../../helper/ApiList";
import {
  STORE_SIGNUP_FAIL,
  STORE_SIGNUP_REQUEST,
  STORE_SIGNUP_SUCCESS,
} from "../../constants/Constants";
import { loaderAction } from "../loaderAction";

export const signUpUser = (body) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: STORE_SIGNUP_REQUEST });
  const { data } = await axios.post(Api.storeRegister, body);
  if (data.response.status.statusCode === 200) {
    dispatch({
      type: STORE_SIGNUP_SUCCESS,
      payload: {
        message: "User created successfully",
        accessToken: data.response.storeData.accessToken,
        data: data.response.storeData,
      },
    });
    dispatch(loaderAction(false));
  } else {
    const { status } = data.response;
    alert(status.customMessage);
    dispatch({ type: STORE_SIGNUP_FAIL, payload: data.response });

    dispatch(loaderAction(false));
  }
};
