import React, { useState, useEffect } from "react";
import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import { EditIcon } from "../../../utils/Icons";
import Heading from "../../Heading/Heading";
import EditAddMenuModal from "./AddMenuModal";
import MaterialTable from "material-table";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "../../../utils/useStyles";
import { Api } from "../../../helper/ApiList";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Toast } from "react-bootstrap";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Avatar from "@mui/material/Avatar";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getMenuInfo } from "../../../services/actions";
export default function Menu() {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const { token } = useSelector((state) => state.isStoreAdmin);
  const [editMenuBtn, setEditMenuBtn] = React.useState(false);
  const [rowData, setRowData] = useState([]);

  React.useEffect(() => {
    let body = {
      serviceType: "",
    };
    const fetchMenus = async () => {
      await axios
        .post(Api.showProducts, body, {
          headers: { Authorization: token },
        })
        .then((res) => {
          if (res.data.response) {
            const { status, result, storeData } = res.data.response;

            if (status.statusCode === 200) {
              setRowData(result);
            }
          }
        })
        .catch((err) => {
          alert.error(err.message);
        });
    };
    fetchMenus();
  }, [token, alert]);

  const columns = [
    { title: "Sr. No", field: "index" },
    {
      title: "Img",
      field: "img",
      render: (item) => (
        <>
          {item.img ? (
            <LazyLoadImage
              alt={""}
              effect="blur"
              src={item.img}
              height="50"
              width="50"
            />
          ) : (
            <Avatar src="" sx={{ width: 24, height: 24 }} />
          )}
        </>
      ),
    },
    { title: "Product Name", field: "productName" },
    { title: "Price", field: "price" },
    { title: "Category", field: "category" },
    { title: "CreatedOn", field: "createdOn" },
  ];

  let tableData =
    rowData.length > 0
      ? rowData.map((item, index) => {
          return {
            index: index + 1,
            img: process.env.REACT_APP_IMAGE_URL + item?.image,
            productName: item?.title,
            price: "$" + item?.variants[0]?.price,
            category: "Chinese",
            createdOn: item?.createdAt.slice(0, 10),
            _id: item._id,
            rowData: item,
          };
        })
      : [];

  const actions = [
    {
      icon: EditIcon,
      tooltip: "Edit Menu",
      onClick: (event, rowData) => {
        dispatch(getMenuInfo(rowData.rowData));
        history.push("/edit-menu");
      },
    },
  ];

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading addMenuBtn={true} />
        <div className="d-none">
          <EditAddMenuModal
            editMenuBtn={editMenuBtn}
            setEditMenuBtn={setEditMenuBtn}
          />
        </div>
        <div className="mt-2">
          {loading ? (
            <CircularProgress />
          ) : (
            <MaterialTable
              options={{
                actionsColumnIndex: -1,
              }}
              data={tableData && tableData}
              columns={columns && columns}
              title=""
              onRowClick={(event, rowData) => console.log(rowData)}
              actions={actions && actions}
            />
          )}
        </div>
      </main>
    </div>
  );
}
