import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useStyles } from "../../../utils/useStyles";
import Heading from "../../Heading/Heading";
import Sidebar from "../../Super-Admin/Sidebar/Sidebar";

import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Api } from "../../../helper/ApiList";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { loaderAction } from "../../../services/actions/loaderAction";

import { useAlert } from "react-alert";
import useFetch from "../../../hooks/useFetch";
const AddMenus = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useSelector((state) => state.isStoreAdmin);
  const { type } = useFetch(Api.showStoreAvailableServiceProfile, token, "get");
  const dispatch = useDispatch();
  const alert = useAlert();
  const [upload, setUpload] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [category, setCategory] = useState([]);
  const [menuDetails, setMenuDetails] = useState({
    serviceType: "",
    title: "",
    description: "",
    // upc: "",
    // skuCode: "",
    categoryIds: "",
    variants: [
      {
        productPackage: "",
        productPackageUnit: "",
        price: "",
        finalPrice: "",
        quantity: "",
        sizeSku: "",
        sizeUpc: "",
      },
    ],
  });

  const createCategoryImagesChange = (e) => {
    const files = Array.from(e.target.files);

    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
    handleMenuImage(e);
  };

  React.useEffect(() => {
    const fetchAgain = async () => {
      await axios({
        method: "post",
        headers: { Authorization: `${token}` },
        url: Api.getCategoriesList,
      })
        .then((res) => {
          const { data } = res;

          if (data.response) {
            const { status, result } = data.response;

            if (status.statusCode === 200) {
              setCategory(result);
            }
          }
        })
        .catch((err) => {});
    };
    fetchAgain();
  }, [token]);

  const handleForm = (e) => {
    const { name, value } = e.target;

    let data = { ...menuDetails };

    if ("categoryIds" === name) {
      data[name] = [value];
    } else if (
      "productPackage" === name ||
      "productPackageUnit" === name ||
      "price" === name ||
      "finalPrice" === name ||
      "quantity" === name ||
      "sizeUpc" === name ||
      "sizeSku" === name
    ) {
      data.variants[0][name] = value;
    } else {
      data[name] = value;
    }
    setMenuDetails(data);
  };

  const handleMenuImage = async (e) => {
    dispatch(loaderAction(true));

    const file = e.target.files;
    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("name", fileData);

    myForm.append("file", fileData);

    const { data } = await axios({
      url: `${Api.fileUpload}`,
      method: "post",
      data: myForm,
    });
    if (data.status === 1) {
      setUpload(data.data);
      alert.success(data.message);
    } else {
      alert.info(data.message);
    }

    dispatch(loaderAction(false));
  };

  const handleSubmit = async () => {
    let inputData = menuDetails;
    inputData["image"] = upload;

    if (upload) {
      dispatch(loaderAction(true));
      let config = {
        headers: { Authorization: `${token}` },
      };

      try {
        const { data } = await axios.post(Api.addProduct, inputData, config);

        const { response } = data;
        if (response.status.statusCode === 200) {
          alert.success("Menu has been successfully added");
          history.push("/Menu");
        } else {
          alert.info(response.status.customMessage);
        }
        dispatch(loaderAction(false));
      } catch (error) {
        const { response } = error;

        alert.error(response.data.message);
        dispatch(loaderAction(false));
      }

      dispatch(loaderAction(false));
    } else {
      alert.info("Please upload the image");
    }
  };

  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />

        <div className="mt-5">
          <Container>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="title"
                    placeholder="Product Name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Service types</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="serviceType"
                    onChange={(e) => handleForm(e)}
                  >
                    <option></option>
                    {type && <option value={type.toLowerCase()}>{type}</option>}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Category Name</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="categoryIds"
                    onChange={(e) => handleForm(e)}
                  >
                    <option>Open this select menu</option>
                    {category && category.length > 0 ? (
                      category &&
                      category.map((cat, index) => (
                        <option value={cat._id} key={index}>
                          {cat.name}
                        </option>
                      ))
                    ) : (
                      <option>Not found</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product Package</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="productPackage"
                    placeholder="100gm"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Product Package Unit</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="productPackageUnit"
                    onChange={(e) => handleForm(e)}
                  >
                    <option>Open this Product Package Unit</option>
                    <option value="gm">gm</option>
                    <option value="kg">kg</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="price"
                    placeholder="$15.00"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Final Price</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="finalPrice"
                    placeholder="$15.00"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="quantity"
                    placeholder="Quantity"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Sku Code</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="skuCode"
                    placeholder="Sku Code"
                  />
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Size Upc</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="sizeUpc"
                    placeholder="Size Upc"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {/* <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Upc123</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => handleForm(e)}
                    name="upc"
                    placeholder="Upc"
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                  />
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => handleForm(e)}
                    placeholder="Lorem ipsum"
                    name="description"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>

          <Container>
            <p className="ml-4">Upload Image</p>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="contained-button-file"
              onChange={createCategoryImagesChange}
            />
            <div className="file">
              <label htmlFor="contained-button-file" className="file_upload">
                {imagesPreview.length === 0 ? (
                  <CollectionsOutlinedIcon className="upload_image" />
                ) : (
                  imagesPreview.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      className="max-w-100 img_preview"
                      alt="Category Preview"
                    />
                  ))
                )}
              </label>

              {/* <span id="createCategoryFormImage">
              {imagesPreview.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  className="max-w-100 img_preview"
                  alt="Category Preview"
                />
              ))}
            </span> */}
            </div>
          </Container>
          <div className="div" style={{ float: "right" }}>
            <Button onClick={() => history.push("/menu")} className="cancel">
              Cancel
            </Button>
            <Button className="submit" onClick={() => handleSubmit()}>
              Submit
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddMenus;
