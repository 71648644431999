import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PaidIcon from "@mui/icons-material/Paid";
export const SidebarMenuTitleIcons = [
  {
    title: "Users",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="31.429"
        height="22"
        viewBox="0 0 31.429 22"
      >
        <path
          id="users"
          d="M9.429,13.25a5.5,5.5,0,1,0-5.5-5.5A5.5,5.5,0,0,0,9.429,13.25ZM13.2,14.821h-.408a7.594,7.594,0,0,1-6.728,0H5.657A5.659,5.659,0,0,0,0,20.479v1.414A2.358,2.358,0,0,0,2.357,24.25H16.5a2.358,2.358,0,0,0,2.357-2.357V20.479A5.659,5.659,0,0,0,13.2,14.821ZM23.571,13.25a4.714,4.714,0,1,0-4.714-4.714A4.716,4.716,0,0,0,23.571,13.25Zm2.357,1.571h-.187a6.193,6.193,0,0,1-4.341,0h-.187a5.454,5.454,0,0,0-2.735.756,7.187,7.187,0,0,1,1.95,4.9v1.886c0,.108-.025.211-.029.314h8.672a2.358,2.358,0,0,0,2.357-2.357,5.5,5.5,0,0,0-5.5-5.5Z"
          transform="translate(0 -2.25)"
        />
      </svg>
    ),
  },
  {
    title: "Stores",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.837"
        height="20.036"
        viewBox="0 0 22.837 20.036"
      >
        <path
          id="metro-shop"
          d="M10.613,11.2l1-6.574H6.4L4.216,10.263a1.952,1.952,0,0,0-.1.626c0,1.383,1.459,2.5,3.262,2.5A3.092,3.092,0,0,0,10.613,11.2Zm4.919,2.191c1.8,0,3.262-1.122,3.262-2.5,0-.051,0-.1-.006-.152l-.646-6.11h-5.22l-.647,6.1c0,.051-.005.1-.005.157,0,1.383,1.46,2.5,3.262,2.5Zm6.344,1.31v4.952H9.188V14.711a5.4,5.4,0,0,1-1.813.311,5.3,5.3,0,0,1-.724-.061v7.951a1.769,1.769,0,0,0,1.774,1.753H22.636a1.771,1.771,0,0,0,1.776-1.753V14.961a5.521,5.521,0,0,1-.724.061A5.334,5.334,0,0,1,21.875,14.7Zm4.973-4.441L24.665,4.627H19.447l1,6.564a3.084,3.084,0,0,0,3.239,2.2c1.8,0,3.262-1.122,3.262-2.5A1.987,1.987,0,0,0,26.849,10.263Z"
          transform="translate(-4.113 -4.627)"
        />
      </svg>
    ),
  },
  {
    title: "Restaurants",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.837"
        height="20.036"
        viewBox="0 0 22.837 20.036"
      >
        <path
          id="metro-shop"
          d="M10.613,11.2l1-6.574H6.4L4.216,10.263a1.952,1.952,0,0,0-.1.626c0,1.383,1.459,2.5,3.262,2.5A3.092,3.092,0,0,0,10.613,11.2Zm4.919,2.191c1.8,0,3.262-1.122,3.262-2.5,0-.051,0-.1-.006-.152l-.646-6.11h-5.22l-.647,6.1c0,.051-.005.1-.005.157,0,1.383,1.46,2.5,3.262,2.5Zm6.344,1.31v4.952H9.188V14.711a5.4,5.4,0,0,1-1.813.311,5.3,5.3,0,0,1-.724-.061v7.951a1.769,1.769,0,0,0,1.774,1.753H22.636a1.771,1.771,0,0,0,1.776-1.753V14.961a5.521,5.521,0,0,1-.724.061A5.334,5.334,0,0,1,21.875,14.7Zm4.973-4.441L24.665,4.627H19.447l1,6.564a3.084,3.084,0,0,0,3.239,2.2c1.8,0,3.262-1.122,3.262-2.5A1.987,1.987,0,0,0,26.849,10.263Z"
          transform="translate(-4.113 -4.627)"
        />
      </svg>
    ),
  },
  {
    title: "Drivers",
    icons: (
      <svg
        id="driver"
        xmlns="http://www.w3.org/2000/svg"
        width="20.783"
        height="23.646"
        viewBox="0 0 20.783 23.646"
      >
        <path
          id="Path_31707"
          data-name="Path 31707"
          d="M101.84,3.371a6.908,6.908,0,0,0-11.789-.129h0a1.336,1.336,0,0,0-.259,1.58,1.378,1.378,0,0,0,1.239.766h9.7a1.386,1.386,0,0,0,1.109-2.217Zm-4.978.139-.98.98-.98-.98.98-.98Zm0,0"
          transform="translate(-85.491)"
        />
        <path
          id="Path_31708"
          data-name="Path 31708"
          d="M257.224,290.855l-.026.027-3.042,10.187h1.053l2.343-3.56-.736-1.472,2.068-2.069Zm0,0"
          transform="translate(-242.418 -277.422)"
        />
        <path
          id="Path_31709"
          data-name="Path 31709"
          d="M97.147,301.069H98.2l-3.042-10.187-.026-.026-1.66,3.112,2.068,2.069-.736,1.472Zm0,0"
          transform="translate(-89.156 -277.423)"
        />
        <path
          id="Path_31710"
          data-name="Path 31710"
          d="M169.5,318.777l2.563,8.591,2.563-8.591a6.165,6.165,0,0,1-1.87.52v4.893h-1.385V319.3a6.166,6.166,0,0,1-1.871-.52Zm0,0"
          transform="translate(-161.668 -304.055)"
        />
        <path
          id="Path_31711"
          data-name="Path 31711"
          d="M0,275.849v6.281H6.326l-2.276-3.46.649-1.3-2.089-2.088L4.9,271H4.849A4.855,4.855,0,0,0,0,275.849Zm0,0"
          transform="translate(0 -258.484)"
        />
        <path
          id="Path_31712"
          data-name="Path 31712"
          d="M319.349,275.849A4.855,4.855,0,0,0,314.5,271h-.046l2.284,4.284-2.088,2.088.649,1.3-2.277,3.46h6.326Zm0,0"
          transform="translate(-298.567 -258.484)"
        />
        <path
          id="Path_31713"
          data-name="Path 31713"
          d="M157.293,152.386a2.083,2.083,0,0,0,1.96-1.386h-6.691a2.083,2.083,0,0,0,1.96,1.386Zm0,0"
          transform="translate(-145.517 -144.026)"
        />
        <path
          id="Path_31714"
          data-name="Path 31714"
          d="M120.071,151H120v2.078a4.849,4.849,0,0,0,9.7,0V151h-.07a3.469,3.469,0,0,1-3.394,2.771h-2.771A3.469,3.469,0,0,1,120.071,151Zm0,0"
          transform="translate(-114.458 -144.026)"
        />
      </svg>
    ),
  },
  {
    title: "Tax",
    icons: <PaymentsIcon />,
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="22.837"
    //   height="20.036"
    //   viewBox="0 0 22.837 20.036"
    // >
    //   <path
    //     id="metro-shop"
    //     d="M10.613,11.2l1-6.574H6.4L4.216,10.263a1.952,1.952,0,0,0-.1.626c0,1.383,1.459,2.5,3.262,2.5A3.092,3.092,0,0,0,10.613,11.2Zm4.919,2.191c1.8,0,3.262-1.122,3.262-2.5,0-.051,0-.1-.006-.152l-.646-6.11h-5.22l-.647,6.1c0,.051-.005.1-.005.157,0,1.383,1.46,2.5,3.262,2.5Zm6.344,1.31v4.952H9.188V14.711a5.4,5.4,0,0,1-1.813.311,5.3,5.3,0,0,1-.724-.061v7.951a1.769,1.769,0,0,0,1.774,1.753H22.636a1.771,1.771,0,0,0,1.776-1.753V14.961a5.521,5.521,0,0,1-.724.061A5.334,5.334,0,0,1,21.875,14.7Zm4.973-4.441L24.665,4.627H19.447l1,6.564a3.084,3.084,0,0,0,3.239,2.2c1.8,0,3.262-1.122,3.262-2.5A1.987,1.987,0,0,0,26.849,10.263Z"
    //     transform="translate(-4.113 -4.627)"
    //   />
    // </svg>
  },
  {
    title: "Courier",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.837"
        height="20.036"
        viewBox="0 0 22.837 20.036"
      >
        <path
          id="metro-shop"
          d="M10.613,11.2l1-6.574H6.4L4.216,10.263a1.952,1.952,0,0,0-.1.626c0,1.383,1.459,2.5,3.262,2.5A3.092,3.092,0,0,0,10.613,11.2Zm4.919,2.191c1.8,0,3.262-1.122,3.262-2.5,0-.051,0-.1-.006-.152l-.646-6.11h-5.22l-.647,6.1c0,.051-.005.1-.005.157,0,1.383,1.46,2.5,3.262,2.5Zm6.344,1.31v4.952H9.188V14.711a5.4,5.4,0,0,1-1.813.311,5.3,5.3,0,0,1-.724-.061v7.951a1.769,1.769,0,0,0,1.774,1.753H22.636a1.771,1.771,0,0,0,1.776-1.753V14.961a5.521,5.521,0,0,1-.724.061A5.334,5.334,0,0,1,21.875,14.7Zm4.973-4.441L24.665,4.627H19.447l1,6.564a3.084,3.084,0,0,0,3.239,2.2c1.8,0,3.262-1.122,3.262-2.5A1.987,1.987,0,0,0,26.849,10.263Z"
          transform="translate(-4.113 -4.627)"
        />
      </svg>
    ),
  },
  {
    title: "Vehicle",
    icons: (
      <LocalShippingIcon />
      // <svg
      //   xmlns="http://www.w3.org/2000/svg"
      //   width="22.837"
      //   height="20.036"
      //   viewBox="0 0 22.837 20.036"
      // >
      //   <path
      //     id="metro-shop"
      //     d="M10.613,11.2l1-6.574H6.4L4.216,10.263a1.952,1.952,0,0,0-.1.626c0,1.383,1.459,2.5,3.262,2.5A3.092,3.092,0,0,0,10.613,11.2Zm4.919,2.191c1.8,0,3.262-1.122,3.262-2.5,0-.051,0-.1-.006-.152l-.646-6.11h-5.22l-.647,6.1c0,.051-.005.1-.005.157,0,1.383,1.46,2.5,3.262,2.5Zm6.344,1.31v4.952H9.188V14.711a5.4,5.4,0,0,1-1.813.311,5.3,5.3,0,0,1-.724-.061v7.951a1.769,1.769,0,0,0,1.774,1.753H22.636a1.771,1.771,0,0,0,1.776-1.753V14.961a5.521,5.521,0,0,1-.724.061A5.334,5.334,0,0,1,21.875,14.7Zm4.973-4.441L24.665,4.627H19.447l1,6.564a3.084,3.084,0,0,0,3.239,2.2c1.8,0,3.262-1.122,3.262-2.5A1.987,1.987,0,0,0,26.849,10.263Z"
      //     transform="translate(-4.113 -4.627)"
      //   />
      // </svg>
    ),
  },
  {
    title: "Category",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.82"
        height="16.116"
        viewBox="0 0 18.82 16.116"
      >
        <g id="category" transform="translate(0.171 -14.284)">
          <rect
            id="Rectangle_267"
            data-name="Rectangle 267"
            width="3.575"
            height="3.575"
            transform="translate(-0.099 20.548)"
          />
          <rect
            id="Rectangle_268"
            data-name="Rectangle 268"
            width="3.764"
            height="3.764"
            transform="translate(-0.171 14.284)"
          />
          <rect
            id="Rectangle_269"
            data-name="Rectangle 269"
            width="3.575"
            height="3.575"
            transform="translate(-0.099 26.826)"
          />
          <rect
            id="Rectangle_270"
            data-name="Rectangle 270"
            width="12.69"
            height="3.575"
            transform="translate(5.888 20.548)"
          />
          <rect
            id="Rectangle_271"
            data-name="Rectangle 271"
            width="12.965"
            height="3.764"
            transform="translate(5.684 14.284)"
          />
          <rect
            id="Rectangle_272"
            data-name="Rectangle 272"
            width="12.69"
            height="3.575"
            transform="translate(5.888 26.826)"
          />
        </g>
      </svg>
    ),
  },
  {
    title: "Orders",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.734"
        height="17.313"
        viewBox="0 0 24.734 17.313"
      >
        <path
          id="orders"
          d="M16.449,10.905a1.873,1.873,0,0,1-1.6-.9L12.365,5.881,9.884,10a1.88,1.88,0,0,1-1.6.908,1.777,1.777,0,0,1-.514-.073L2.472,9.321V16.2a1.233,1.233,0,0,0,.935,1.2l8.355,2.091a2.512,2.512,0,0,0,1.2,0L21.322,17.4a1.239,1.239,0,0,0,.935-1.2V9.321l-5.294,1.511A1.777,1.777,0,0,1,16.449,10.905Zm8.216-4.336L22.675,2.6a.631.631,0,0,0-.645-.344L12.365,3.485l3.544,5.878a.635.635,0,0,0,.715.282l7.648-2.183a.636.636,0,0,0,.394-.893ZM2.055,2.6.064,6.569a.63.63,0,0,0,.39.889L8.1,9.641a.635.635,0,0,0,.715-.282l3.548-5.874L2.7,2.253A.631.631,0,0,0,2.055,2.6Z"
          transform="translate(0.003 -2.247)"
        />
      </svg>
    ),
  },
  {
    title: "Transaction",
    icons: <PaidIcon />,
  },
  {
    title: "Profile",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.242"
        height="17.242"
        viewBox="0 0 17.242 17.242"
      >
        <path
          id="profile"
          d="M8.621,9.7A4.849,4.849,0,1,0,3.772,4.849,4.851,4.851,0,0,0,8.621,9.7Zm4.311,1.078H11.076a5.862,5.862,0,0,1-4.91,0H4.311A4.31,4.31,0,0,0,0,15.087v.539a1.617,1.617,0,0,0,1.616,1.616H15.626a1.617,1.617,0,0,0,1.616-1.616v-.539A4.31,4.31,0,0,0,12.932,10.776Z"
        />
      </svg>
    ),
  },

  {
    title: "Logout",
    icons: (
      <svg
        id="logout"
        xmlns="http://www.w3.org/2000/svg"
        width="21.68"
        height="21.68"
        viewBox="0 0 21.68 21.68"
      >
        <path
          id="Path_31720"
          data-name="Path 31720"
          d="M9.936,13.55a2.71,2.71,0,1,1,0-5.42h4.517V2.484A2.486,2.486,0,0,0,11.969,0H2.484A2.486,2.486,0,0,0,0,2.484V19.2a2.486,2.486,0,0,0,2.484,2.484h9.485A2.486,2.486,0,0,0,14.453,19.2V13.55Z"
        />
        <path
          id="Path_31721"
          data-name="Path 31721"
          d="M17.784,15.964a.9.9,0,0,1-.557-.835V12.42H10.9a.9.9,0,0,1,0-1.807h6.323V7.9a.9.9,0,0,1,1.542-.639l3.613,3.613a.9.9,0,0,1,0,1.277l-3.613,3.613A.9.9,0,0,1,17.784,15.964Z"
          transform="translate(-0.967 -0.677)"
        />
      </svg>
    ),
  },
];

// Super admin menus

export const SidebarRestaurantMenuTitleIcons = [
  {
    title: "All Orders",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.734"
        height="17.313"
        viewBox="0 0 24.734 17.313"
      >
        <path
          id="orders"
          d="M16.449,10.905a1.873,1.873,0,0,1-1.6-.9L12.365,5.881,9.884,10a1.88,1.88,0,0,1-1.6.908,1.777,1.777,0,0,1-.514-.073L2.472,9.321V16.2a1.233,1.233,0,0,0,.935,1.2l8.355,2.091a2.512,2.512,0,0,0,1.2,0L21.322,17.4a1.239,1.239,0,0,0,.935-1.2V9.321l-5.294,1.511A1.777,1.777,0,0,1,16.449,10.905Zm8.216-4.336L22.675,2.6a.631.631,0,0,0-.645-.344L12.365,3.485l3.544,5.878a.635.635,0,0,0,.715.282l7.648-2.183a.636.636,0,0,0,.394-.893ZM2.055,2.6.064,6.569a.63.63,0,0,0,.39.889L8.1,9.641a.635.635,0,0,0,.715-.282l3.548-5.874L2.7,2.253A.631.631,0,0,0,2.055,2.6Z"
          transform="translate(0.003 -2.247)"
        />
      </svg>
    ),
  },
  {
    title: "Menu",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.734"
        height="17.313"
        viewBox="0 0 24.734 17.313"
      >
        <path
          id="orders"
          d="M16.449,10.905a1.873,1.873,0,0,1-1.6-.9L12.365,5.881,9.884,10a1.88,1.88,0,0,1-1.6.908,1.777,1.777,0,0,1-.514-.073L2.472,9.321V16.2a1.233,1.233,0,0,0,.935,1.2l8.355,2.091a2.512,2.512,0,0,0,1.2,0L21.322,17.4a1.239,1.239,0,0,0,.935-1.2V9.321l-5.294,1.511A1.777,1.777,0,0,1,16.449,10.905Zm8.216-4.336L22.675,2.6a.631.631,0,0,0-.645-.344L12.365,3.485l3.544,5.878a.635.635,0,0,0,.715.282l7.648-2.183a.636.636,0,0,0,.394-.893ZM2.055,2.6.064,6.569a.63.63,0,0,0,.39.889L8.1,9.641a.635.635,0,0,0,.715-.282l3.548-5.874L2.7,2.253A.631.631,0,0,0,2.055,2.6Z"
          transform="translate(0.003 -2.247)"
        />
      </svg>
    ),
  },
  {
    title: "Promo code",
    icons: <LocalGroceryStoreIcon />,
  },
  {
    title: "Profile",
    icons: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.242"
        height="17.242"
        viewBox="0 0 17.242 17.242"
      >
        <path
          id="profile"
          d="M8.621,9.7A4.849,4.849,0,1,0,3.772,4.849,4.851,4.851,0,0,0,8.621,9.7Zm4.311,1.078H11.076a5.862,5.862,0,0,1-4.91,0H4.311A4.31,4.31,0,0,0,0,15.087v.539a1.617,1.617,0,0,0,1.616,1.616H15.626a1.617,1.617,0,0,0,1.616-1.616v-.539A4.31,4.31,0,0,0,12.932,10.776Z"
        />
      </svg>
    ),
  },
  {
    title: "Logout",
    icons: (
      <svg
        id="logout"
        xmlns="http://www.w3.org/2000/svg"
        width="21.68"
        height="21.68"
        viewBox="0 0 21.68 21.68"
      >
        <path
          id="Path_31720"
          data-name="Path 31720"
          d="M9.936,13.55a2.71,2.71,0,1,1,0-5.42h4.517V2.484A2.486,2.486,0,0,0,11.969,0H2.484A2.486,2.486,0,0,0,0,2.484V19.2a2.486,2.486,0,0,0,2.484,2.484h9.485A2.486,2.486,0,0,0,14.453,19.2V13.55Z"
        />
        <path
          id="Path_31721"
          data-name="Path 31721"
          d="M17.784,15.964a.9.9,0,0,1-.557-.835V12.42H10.9a.9.9,0,0,1,0-1.807h6.323V7.9a.9.9,0,0,1,1.542-.639l3.613,3.613a.9.9,0,0,1,0,1.277l-3.613,3.613A.9.9,0,0,1,17.784,15.964Z"
          transform="translate(-0.967 -0.677)"
        />
      </svg>
    ),
  },
];
