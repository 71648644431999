import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import "./Profile.css";
import { Col, Form, Row, Button, Container } from "react-bootstrap";
import { useStyles } from "../../../utils/useStyles";
import useFetch from "../../../hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../../helper/ApiList";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";

import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import {
  getCoordinates,
  updateRestaurantAndGrocery,
} from "../../../helper/Api";
import { loaderAction } from "../../../services/actions/loaderAction";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function EditStoreProfile() {
  const classes = useStyles();

  const alert = useAlert();
  let API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });
  let options = {
    open: false,
    vertical: "top",
    horizontal: "right",
  };

  const { token } = useSelector((store) => store.isStoreAdmin);

  const dispatch = useDispatch();
  const history = useHistory();
  const originRef = useRef();
  const [upload, setUpload] = useState(null);

  const [open, setOpen] = React.useState(false);
  let method = "GET";
  const [id, setId] = useState(null);

  const { data, type } = useFetch(
    Api.showStoreAvailableServiceProfile,
    token,
    method
  );

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    latitude: "",
    longitude: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    countryCode: "",
    contactNumber: "",
  });

  const calculateDist = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let input = { ...formData };

    if (!originRef.current.value) {
      return;
    }
    const { data } = await getCoordinates(originRef.current.value);

    if (data.status === "OK") {
      const { formatted_address, geometry } = data?.results[0];
      let latitude = geometry?.location.lat;
      let longitude = geometry?.location.lng;

      input[name] = formatted_address;
      input["latitude"] = latitude;
      input["longitude"] = longitude;

      setFormData(input);

      console.log(latitude, longitude);
    } else {
      alert.error("Please enter a valid address");
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    const { name, value, maxLength } = e.target;

    let data = { ...formData };

    if (name === "contactNumber") {
      const message =
        value.length < maxLength - 1 ? handleClick() : handleClose();
    }
    data[name] = value;
    setFormData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      alert.error("Session expired, please login again");
      setTimeout(() => {
        history.push("/restaurant/login");
      }, 900);
      return false;
    }

    if (formData.contactNumber.length < 9) {
      alert.info("Please enter a valid phoneNumber");
      return false;
    }

    dispatch(loaderAction(true));
    let url =
      type === "Food" ? Api.updateRestaurantProfile : Api.updateGroceryProfile;

    let body =
      type === "Food"
        ? (formData["restaurant_id"] = id)
        : (formData["grocery_id"] = id);

    try {
      const data = await updateRestaurantAndGrocery(url, formData, token, type);

      if (data?.data?.response.status.statusCode === 200) {
        alert.success("Profile info has been updated successfully");
        setTimeout(() => {
          history.push("/Profile");
        }, 900);
      } else {
        alert.error(
          data.response.data.message || data.response.data.customMessage
        );
      }

      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };

  const createCategoryImagesChange = async (e) => {
    dispatch(loaderAction(true));

    const file = e.target.files;
    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("name", fileData);

    myForm.append("file", fileData);

    const { data } = await axios({
      url: `${Api.fileUpload}`,
      method: "post",
      data: myForm,
    });
    if (data.status === 1) {
      setUpload(data.data);

      let body = { ...formData };

      body["image"] = data.data;
      setFormData(body);
      alert.success(data.message);
    } else {
      alert.info(data.message);
    }

    dispatch(loaderAction(false));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      let res = data[0];
      if (res) {
        let form = { ...formData };

        form["name"] = res?.name;
        form["description"] = res?.description;
        form["image"] = res?.image;
        form["latitude"] = res?.location?.coordinates[0];
        form["longitude"] = res?.location?.coordinates[1];
        form["email"] = res?.email;
        form["address"] = res?.address;
        form["state"] = res?.state;
        form["city"] = res?.city;
        form["country"] = res?.country;
        form["zipCode"] = res?.zipCode;
        form["countryCode"] = res?.countryCode;
        form["contactNumber"] = res?.contactNumber;

        setId(res?._id);
        setUpload(res?.image);
        setFormData(form);
      }
    }
  }, [data]);

  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={options}
              key={options}
            >
              <Alert
                onClose={handleClose}
                severity="warning"
                sx={{ width: "100%" }}
              >
                Entered number is not valid!
              </Alert>
            </Snackbar>
          </Stack>

          <Row className="pt-5 edit_profile_form">
            <Form
              className="w-100"
              // autoComplete="off"
              autoComplete="new-password"
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter restaurant name"
                  name="name"
                  required={true}
                  value={formData.name}
                  onChange={(e) => handleInput(e)}

                  //   onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {isLoaded ? (
                <Autocomplete>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address </Form.Label>
                    <Form.Control
                      type="address"
                      placeholder="Enter address"
                      name="address"
                      ref={originRef}
                      onBlur={(e) => calculateDist(e)}
                      required={true}
                      defaultValue={formData.address}
                      // onChange={(e) => handleInput(e)}
                      //   onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Autocomplete>
              ) : (
                "Something went wrong with your location api"
              )}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>City </Form.Label>
                <Form.Control
                  type="city"
                  placeholder="Enter city"
                  name="city"
                  required={true}
                  value={formData.city}
                  onChange={(e) => handleInput(e)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>state</Form.Label>
                <Form.Control
                  type="state"
                  placeholder="state"
                  name="state"
                  required={true}
                  value={formData.state}
                  onChange={(e) => handleInput(e)}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="India"
                      name="country"
                      required={true}
                      value={formData.country}
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="zipCode"
                      placeholder="166001"
                      name="zipCode"
                      required={true}
                      value={formData.zipCode}
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Country Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="+91"
                      name="countryCode"
                      required={true}
                      value={formData.countryCode}
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {" "}
                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>PhoneNumber</Form.Label>
                    <Form.Control
                      type="phoneNumber"
                      placeholder="123456789"
                      name="contactNumber"
                      maxLength={11}
                      required={true}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={formData.contactNumber}
                      onChange={(e) => handleInput(e)}
                      //   onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <p className="ml-1 edit_profile_form form-label">Upload Image</p>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={createCategoryImagesChange}
              />
              <div className="file">
                <label htmlFor="contained-button-file" className="file_upload">
                  {upload ? (
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + upload}
                      className="max-w-100 img_preview"
                      alt=""
                    />
                  ) : (
                    <CollectionsOutlinedIcon className="upload_image" />
                  )}
                </label>
              </div>

              {/* <Link to="/" className="text-white"> */}
              <div className="text-right">
                <Button
                  className="btn btn-success"
                  type="submit"
                  // onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Row>
        </main>
      </div>
    </>
  );
}
