import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import LoginIcon from "./logo-login.png";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { storeAdminLogin } from "../../services/actions/store/auth.action";

function RestaurantAdminLogin() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const isNotAdmin = false;

  const { isStoreAuthenticated, isStoreUser, message } = useSelector(
    (state) => state.isStoreAdmin
  );

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    message && alert.error(message);
  }, [message, alert]);

  const loginHandle = () => {
    if (!email || !password) {
      alert.info("Please enter a valid email or password");
    } else {
      let data = {
        email,
        password,
        deviceType: "WEB",
        deviceToken: "###",
      };
      dispatch(storeAdminLogin(isNotAdmin, data));
    }
  };

  if (isStoreAuthenticated && !isStoreUser) {
    return <Redirect to="/all-orders" />;
  }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="login_left_side">
            <div className="logo">
              <img
                src={LoginIcon}
                alt=""
                srcSet=""
                className="logo_login_icon"
              />
            </div>
          </Col>
          <Col className="login_right_side">
            <div className="w-75" autoComplete="off">
              <div className="logo">
                <img
                  src={LoginIcon}
                  alt=""
                  srcSet=""
                  className="logo_login_icon"
                />
              </div>
              <p className="login_heading">Login</p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              {/* <Link className="text-white" to="/all-orders"> */}
              <Button
                className="login_button"
                type="submit"
                onClick={() => loginHandle()}
              >
                Login
              </Button>
              {/* </Link> */}

              <p className="mt-4 text-center account">
                Don't have an Account?
                <span>
                  <Link to="/signup" className="sign_up">
                    Sign Up
                  </Link>
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RestaurantAdminLogin;
