import { combineReducers } from "redux";
import { categoryReducer } from "./categoryReducer";
import { driverReducer } from "./driverReducer";
import { loaderReducer } from "./loaderReducer";
import { getMenuInfoReducer, storeReducer } from "./storeReducer";
import { superAdminReducer } from "./superAdminReducer";
import { orderReducer, singleOrderReducer } from "./orderReducer";
import { deleteUserReducer, userReducer } from "./userReducer";
import { openModalReducer } from "./modalReducer";
import { storeAdminReducer } from "./storeReducer/auth.reducer";
import { storeUserReducer } from "./storeReducer/storeUserReducer";

const rootReducer = combineReducers({
  loader: loaderReducer,
  isSuperAdmin: superAdminReducer, // auth data of super admin
  userData: userReducer,
  deleteUser: deleteUserReducer,
  stores: storeReducer,
  categoryList: categoryReducer,
  driverList: driverReducer,
  orderList: orderReducer,
  singleOrder: singleOrderReducer,
  openModal: openModalReducer,

  // store

  isStoreAdmin: storeAdminReducer,
  isStoreSignup: storeUserReducer,
  singleMenu: getMenuInfoReducer,
});

export default rootReducer;
