import React, { useRef, useState } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { LoginIcon } from "../../../utils/Icons";

import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";

import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { addRestaurantAndGrocery, getCoordinates } from "../../../helper/Api";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../../helper/ApiList";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AddGrocery = () => {
  const alert = useAlert();
  let API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  });

  const { storeData, token } = useSelector((store) => store.isStoreSignup);

  const dispatch = useDispatch();

  const originRef = useRef();

  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [upload, setUpload] = useState(null);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: "",
    latitude: "",
    longitude: "",
    email: storeData?.email,
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    countryCode: "",
    contactNumber: "",
  });

  const calculateDist = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let input = { ...formData };

    if (!originRef.current.value) {
      return;
    }
    const { data } = await getCoordinates(originRef.current.value);

    if (data.status === "OK") {
      const { formatted_address, geometry } = data?.results[0];
      let latitude = geometry?.location.lat;
      let longitude = geometry?.location.lng;

      input[name] = formatted_address;
      input["latitude"] = latitude;
      input["longitude"] = longitude;

      setFormData(input);
    } else {
      alert.error("Please enter a valid address");
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    const { name, value, maxLength } = e.target;

    let data = { ...formData };

    if (name === "contactNumber") {
      const message =
        value.length < maxLength - 1 ? handleClick() : handleClose();
    }
    data[name] = value;
    setFormData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      alert.error("Session expired, please login again");
      setTimeout(() => {
        history.push("/restaurant/login");
      }, 900);
    }

    if (formData.contactNumber.length < 9) {
      alert.info("Please enter a valid phoneNumber");
    }

    try {
      const data = await addRestaurantAndGrocery(
        Api.addGrocery,
        formData,
        token
      );
      if (data?.data?.response.status.statusCode === 200) {
        alert.success("Grocery added successfully, Please login!");
        setTimeout(() => {
          history.push("/restaurant/login");
        }, 1000);
      } else {
        alert.error(
          data.response.data.message || data.response.data.customMessage
        );
      }
      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };

  const createCategoryImagesChange = async (e) => {
    dispatch(loaderAction(true));

    const file = e.target.files;
    const fileData = file === null ? null : file[0];

    var myForm = new FormData();
    myForm.append("name", fileData);

    myForm.append("file", fileData);

    const { data } = await axios({
      url: `${Api.fileUpload}`,
      method: "post",
      data: myForm,
    });
    if (data.status === 1) {
      setUpload(data.data);

      let body = { ...formData };

      body["image"] = data.data;
      setFormData(body);
      alert.success(data.message);
    } else {
      alert.info(data.message);
    }

    dispatch(loaderAction(false));
  };

  let options = {
    open: false,
    vertical: "top",
    horizontal: "right",
  };
  return (
    <div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={options}
          key={options}
        >
          <Alert
            onClose={handleClose}
            severity="warning"
            sx={{ width: "100%" }}
          >
            Entered number is not valid!
          </Alert>
        </Snackbar>
      </Stack>
      <Container fluid>
        <Row>
          <Col className="login_left_side">
            <div className="logo">
              <LoginIcon />
            </div>
          </Col>
          <Col className="login_right_side">
            <Form
              className="w-75"
              // autoComplete="off"
              autoComplete="new-password"
              onSubmit={(e) => handleSubmit(e)}
            >
              <p className="login_heading">Add Grocery</p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  required={true}
                  onChange={(e) => handleInput(e)}

                  //   onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {isLoaded ? (
                <Autocomplete>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address </Form.Label>
                    <Form.Control
                      type="address"
                      placeholder="Enter address"
                      name="address"
                      ref={originRef}
                      onBlur={(e) => calculateDist(e)}
                      required={true}
                      // onChange={(e) => handleInput(e)}
                      //   onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Autocomplete>
              ) : (
                "Something went wrong with your location api"
              )}

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>City </Form.Label>
                    <Form.Control
                      type="city"
                      placeholder="Enter city"
                      name="city"
                      required={true}
                      onChange={(e) => handleInput(e)}
                      //   onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>state</Form.Label>
                    <Form.Control
                      type="state"
                      placeholder="state"
                      name="state"
                      required={true}
                      onChange={(e) => handleInput(e)}
                      //   onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="India"
                      name="country"
                      required={true}
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="zipCode"
                      placeholder="166001"
                      name="zipCode"
                      required={true}
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Country Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="+91"
                      name="countryCode"
                      required={true}
                      onChange={(e) => handleInput(e)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {" "}
                  <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                    <Form.Label>PhoneNumber</Form.Label>
                    <Form.Control
                      type="phoneNumber"
                      placeholder="123456789"
                      name="contactNumber"
                      maxLength={11}
                      required={true}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => handleInput(e)}
                      //   onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <p className="ml-1 edit_profile_form form-label">Upload Image</p>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                onChange={createCategoryImagesChange}
              />
              <div className="file">
                <label htmlFor="contained-button-file" className="file_upload">
                  {upload ? (
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + upload}
                      className="max-w-100 img_preview"
                      alt=""
                    />
                  ) : (
                    <CollectionsOutlinedIcon className="upload_image" />
                  )}
                </label>
              </div>
              {/* <Link to="/" className="text-white"> */}
              <Button
                className="login_button"
                type="submit"
                // onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddGrocery;
