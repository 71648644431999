import React from "react";

import MaterialTable from "material-table";
import { Button, Col, Row } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import "./DataTable.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function DataTable({ actions, btnAction }) {
  const [classToggle, setClassToggle] = React.useState("1");

  const location = useLocation();

  React.useEffect(() => {
    setClassToggle(
      location.pathname.substring(1) === "Store-Request" ? "2" : "1"
    );
  }, [location]);

  return (
    <Row>
      <Col className="mt-2">
        {btnAction && btnAction && (
          <>
            <Link to="/Stores">
              <Button
                className={classToggle === "1" ? "tab_btn active" : "tab_btn"}
                onClick={() => {
                  setClassToggle("1");
                }}
              >
                All
              </Button>
            </Link>
            <Link to="/Store-Request">
              <Button
                className={classToggle === "2" ? "tab_btn1 active" : "tab_btn1"}
                onClick={() => {
                  setClassToggle("2");
                }}
              >
                Request
              </Button>
            </Link>
          </>
        )}

        {/* <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <MaterialTable
              options={{
                actionsColumnIndex: -1,
              }}
              data={tData && tData}
              columns={columns && columns}
              title=""
              onRowClick={(event, rowData) => console.log(rowData)}
              actions={actions && actions}
            />
          )}
        </Box> */}
      </Col>
    </Row>
  );
}

export default DataTable;
