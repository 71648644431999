import { CATEGORY_REQUEST, CATEGORY_SUCCESS } from "../constants/Constants";

const initialState = {
  categoryList: null,
};

export const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_REQUEST:
      return {
        initialState,
      };

    case CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
      };

    default:
      return state;
  }
};
