import {
  STORE_FAIL,
  STORE_SUCCESS,
  STORE_REQUEST,
  REQUEST_STORE_REQUEST,
  REQUEST_STORE_SUCCESS,
  MENU_SUCCESS,
  MENU_RESET,
} from "../constants/Constants";

const initialState = {
  stores: [],
  requestStore: null,
};
const menuState = {
  singleMenu: null,
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_REQUEST:
      return {
        initialState,
      };

    case STORE_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      };

    default:
      return state;
  }
};

export const getMenuInfoReducer = (state = menuState, action) => {
  switch (action.type) {
    case MENU_RESET:
      return {
        menuState,
      };

    case MENU_SUCCESS:
      return {
        ...state,
        singleMenu: action.payload,
      };

    default:
      return state;
  }
};
