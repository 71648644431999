import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Sidebar from "../Sidebar/Sidebar";
import Heading from "../../Heading/Heading";

import MaterialTable from "material-table";
import CircularProgress from "@mui/material/CircularProgress";
import { DeleteIcon, EyeIcon } from "../../../utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import { allUsers, deleteUser } from "../../../services/actions/userAction";
import { Box } from "@material-ui/core";
import DataTable from "../../DataTable/DataTable";
import { openModalAction } from "../../../services/actions/modalAction";
import EyeModal from "../../EyeModal/EyeModal";
import { useStyles } from "../../../utils/useStyles";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Avatar from "@mui/material/Avatar";
export default function Users() {
  const classes = useStyles();
  const { adminToken } = useSelector((state) => state.isSuperAdmin);
  const { userData } = useSelector((state) => state.userData);
  const { message } = useSelector((state) => state.deleteUser);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const columns = [
    {
      title: "Sr. No",
      field: "index",
    },
    {
      title: "Img",
      field: "profileImage",
      render: (item) => (
        <>
          {item.profileImage ? (
            <LazyLoadImage
              alt={""}
              effect="blur"
              src={process.env.REACT_APP_IMAGE_URL + item.profileImage}
              height="30"
              width="30"
            />
          ) : (
            <Avatar src="" sx={{ width: 24, height: 24 }} />
          )}
        </>
      ),
    },
    { title: "Name", field: "firstName" },
    { title: "Email", field: "email" },
    { title: "Address", field: "address" },
    { title: "Phone Number", field: "contactNumber" },
  ];

  const actions = [
    {
      icon: EyeIcon,
      tooltip: "Save User",
      onClick: (event, rowData) => dispatch(openModalAction(true, rowData)),
    },
    {
      icon: DeleteIcon,
      tooltip: "Delete User",
      onClick: (event, rowData) =>
        dispatch(deleteUser(rowData._id, adminToken)),
    },
  ];

  useEffect(() => {
    setLoading(true);
    dispatch(allUsers(adminToken));
    setLoading(false);
  }, [dispatch, adminToken]);

  return (
    <div
      className={classes.root}
      onClick={() => dispatch(openModalAction(false))}
    >
      <Sidebar />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Heading />
        <EyeModal />
        {/* Data table */}
        <DataTable />
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <MaterialTable
              options={{
                actionsColumnIndex: -1,
              }}
              data={userData && userData}
              columns={columns && columns}
              title=""
              onRowClick={(event, rowData) => console.log(rowData)}
              actions={actions && actions}
            />
          )}
        </Box>
      </main>
    </div>
  );
}
