import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  SINGLE_ORDER_DETAILS_RESET,
} from "../constants/Constants";
import { SINGLE_ORDER_DETAILS_SUCCESS } from "./../constants/Constants";

const initialState = {
  orderList: null,
};

const singleOrderState = {
  singleOrder: null,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_REQUEST:
      return {
        initialState,
      };

    case ORDER_SUCCESS:
      return {
        ...state,
        orderList: action.payload,
      };

    default:
      return state;
  }
};

export const singleOrderReducer = (state = singleOrderState, action) => {
  switch (action.type) {
    case SINGLE_ORDER_DETAILS_RESET:
      return {
        singleOrderState,
      };

    case SINGLE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        singleOrder: action.payload,
      };

    default:
      return state;
  }
};
