import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loaderAction } from "../services/actions/loaderAction";

const useFetch = (url, token, method) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [type, setType] = useState(null);
  const dispatch = useDispatch();

  const fetchAgain = async () => {
    setLoading(true);
    dispatch(loaderAction(true));
    await axios({
      method: method,
      headers: { Authorization: `${token}` },
      url: url,
    })
      .then((res) => {
        if (res.data.response) {
          const { status, result, storeData, serviceType } = res.data.response;

          if (status.statusCode === 200) {
            if (storeData) {
              setData(storeData);
            } else {
              setType(serviceType);
              setData(result);
            }
          }
        }
        dispatch(loaderAction(false));
      })
      .catch((err) => {
        setError(err.message);
        dispatch(loaderAction(false));
      });
    setLoading(false);
    dispatch(loaderAction(false));
  };

  useEffect(() => {
    fetchAgain();
  }, []);

  return { data, loading, error, fetchAgain, type };
};

export default useFetch;
