import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AddTaxButton = () => {
  return (
    <div className="add_category">
      <Link to="/add-tax" className="">
        <Button className="edit_profile_btn">
          <AddCircleOutlineIcon className="mr-2" />
          Add Tax
        </Button>
      </Link>
    </div>
  );
};

const AddVehicleButton = () => {
  return (
    <div className="add_category">
      <Link to="/add-vehicle" className="">
        <Button className="edit_profile_btn">
          <AddCircleOutlineIcon className="mr-2" />
          Add Vehicle
        </Button>
      </Link>
    </div>
  );
};
const AddPriceButton = () => {
  return (
    <div className="add_category">
      <Link to="/add-courier" className="">
        <Button className="edit_profile_btn">
          <AddCircleOutlineIcon className="mr-2" />
          Add courier price
        </Button>
      </Link>
    </div>
  );
};

export { AddTaxButton, AddVehicleButton, AddPriceButton };
