import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Button, Container } from "react-bootstrap";

import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { useStyles } from "../../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";

import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loaderAction";
import useFetch from "./../../../hooks/useFetch";

export default function AddCourierPrice() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { adminToken } = useSelector((state) => state.isSuperAdmin);
  const { data } = useFetch(Api.getVehiclesList, adminToken, "get");
  const alert = useAlert();

  const [minWeight, setMinWeight] = useState("");
  const [maxWeight, setMaxWeight] = useState("");
  const [unitWeight, setUnitWeight] = useState("");
  const [minDistance, setMinDistance] = useState("");
  const [maxDistance, setMaxDistance] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [priceCurrency, setPriceCurrency] = useState("");
  const [vehicleId, setVehicleId] = useState("");

  const handleSubmit = async () => {
    dispatch(loaderAction(true));
    let config = {
      headers: { Authorization: `${adminToken}` },
    };

    try {
      const { data } = await axios.post(
        Api.addCourierPrice,
        {
          vehicleId,
          net_weight: {
            minWeight,
            maxWeight,
            unit: unitWeight,
          },
          distance: {
            minDistance,
            maxDistance,
            unit,
          },
          price,
          priceCurrency: "usd",
        },
        config
      );

      const { response } = data;
      if (response.status.statusCode === 200) {
        alert.success("Courier Price has been successfully added");
        history.push("/courier");
      } else {
        alert.info(response.status.customMessage);
      }
      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading />

          <Container>
            <Row className="pt-5 edit_profile_form">
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Select Service Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="serviceId"
                    onChange={(e) => setVehicleId(e.target.value)}
                  >
                    <option></option>
                    {data && data.length > 0
                      ? data.map((service, index) => (
                          <option value={service._id} key={index}>
                            {service.name}
                          </option>
                        ))
                      : "not found"}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Min Weight</Form.Label>
                  <Form.Control
                    placeholder=""
                    name={minWeight}
                    onChange={(e) => setMinWeight(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Max Weight</Form.Label>
                  <Form.Control
                    placeholder=""
                    name={maxWeight}
                    onChange={(e) => setMaxWeight(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Weight Unit</Form.Label>
                  <Form.Control
                    placeholder="kg"
                    name={unitWeight}
                    onChange={(e) => setUnitWeight(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Min Distance</Form.Label>
                  <Form.Control
                    type=""
                    name={minDistance}
                    onChange={(e) => setMinDistance(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Max Distance</Form.Label>
                  <Form.Control
                    type=""
                    name={maxDistance}
                    onChange={(e) => setMaxDistance(e.target.value)}
                  />
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Unit</Form.Label>
                  <Form.Control
                    placeholder="km"
                    name={unit}
                    onChange={(e) => setUnit(e.target.value)}
                  />
                </Form.Group>
              </Col> */}

              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Distance Unit</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="serviceId"
                    onChange={(e) => setUnit(e.target.value)}
                  >
                    <option></option>

                    <option value={"Km"}>Km</option>
                    <option value={"mile"}>Mile</option>
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price $</Form.Label>
                  <Form.Control
                    placeholder=""
                    name={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price Currency</Form.Label>
                  <Form.Control
                    placeholder="USD"
                    disabled
                    name={priceCurrency}
                    onChange={(e) => setPriceCurrency(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              className="edit_profile_btn p-4 pt-2 pb-2 "
              onClick={() => handleSubmit()}
            >
              {" "}
              Submit
            </Button>
          </Container>
        </main>
      </div>
    </>
  );
}
