import axios from "axios";
import { Api } from "./ApiList";

export const addCategory = async (token, formData, name, setData) => {
  let success = null;
  let config = {
    headers: {
      // headers: { "Content-Type": "multipart/form-data" },
      Authorization: `${token}`,
    },
  };

  const { data } = await axios({
    url: `${Api.fileUpload}`,
    method: "post",
    config,
    data: formData,
  });

  if (data.status === 1) {
    await axios
      .post(
        Api.addCategory,
        {
          name: name,
          serviceId: "624440087f6e4735a4917f63",
          image: data.data,
          description: "",
        },
        config
      )
      .then((res) => {
        const { status } = res.data.response;

        if (status.statusCode === 200) {
          let message = { message: status.customMessage, error: "" };
          success = message;
        } else {
          let message = { error: status.customMessage, message: "" };
          success = message;
        }
      })
      .catch((err) => {
        let message = { error: err.message, message: "" };
        success = message;
      });
  }
  return success;
};

export const uploadSingleImage = async (token, formData) => {
  let config = {
    headers: {
      Authorization: `${token}`,
    },
  };

  const res = await axios({
    url: `${Api.fileUpload}`,
    config,
    method: "post",
    data: formData,
  });

  console.log(res, "data====");
  return config;
};

export const getCoordinates = async (address) => {
  let API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  return await axios.get(
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&key=" +
      API_KEY
  );
};

export const addRestaurantAndGrocery = async (url, formData, token) => {
  try {
    return await axios.post(url, formData, {
      headers: { Authorization: `${token}` },
    });
  } catch (err) {
    return err;
  }
};

export const updateRestaurantAndGrocery = async (url, formData, token) => {
  try {
    return await axios.put(url, formData, {
      headers: { Authorization: `${token}` },
    });
  } catch (err) {
    return err;
  }
};

export const editRestaurantAndGroceryTimeSlots = async (
  url,
  formData,
  token
) => {
  try {
    return await axios.put(url, formData, {
      headers: { Authorization: `${token}` },
    });
  } catch (err) {
    return err;
  }
};
