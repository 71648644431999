import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { Container } from "@material-ui/core";
import "../Category/Category.css";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../../../helper/Api";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useAlert } from "react-alert";
import CircularProgress from "@mui/material/CircularProgress";

import { Api } from "../../../helper/ApiList";
import axios from "axios";
export default function DriverModal({ open, setOpen, rowData, setRowData }) {
  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );

  const alert = useAlert();

  const [stripeConnectId, setStripeConnectId] = useState("");
  const [loader, setLoader] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setRowData(null);
  };

  const handleSubmit = async (e) => {
    const config = {
      headers: { Authorization: adminToken },
    };

    try {
      if (!stripeConnectId) {
        alert.error("All fields are required");
        return false;
      }

      let body = {
        driverId: rowData?._id,
        firstName: rowData?.firstName,
        lastName: rowData?.lastName,
        address: rowData?.address,
        countryCode: rowData?.countryCode,
        contactNumber: rowData?.contactNumber,
        stripeConnectId,
      };
      setLoader(true);
      const { data } = await axios.put(Api.updateDriverProfile, body, config);
      if (data.response.status.statusCode !== 200) {
        alert.error(data.response.status.customMessage);
      } else {
        handleClose();
        alert.success(data.response.status.customMessage);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      alert.error(error.message);
    }
  };

  return (
    <div className="add_category">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Customer Update</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            disabled="true"
            value={
              rowData?.firstName && rowData?.firstName + " " + rowData?.lastName
            }
            // variant="standard"
            name="name"
          />
          <TextField
            margin="dense"
            label="Email"
            type="text"
            fullWidth
            disabled="true"
            value={rowData?.email}
            // variant="standard"
            name="email"
          />
          <TextField
            margin="dense"
            label="Contact Number"
            type="text"
            fullWidth
            disabled="true"
            value={
              rowData?.countryCode &&
              rowData?.countryCode + " " + rowData?.contactNumber
            }
            // variant="standard"
            name="contactNumber"
          />
          <TextField
            // autoFocus
            margin="dense"
            label="Enter Stripe Connect Id"
            type="text"
            fullWidth
            name="stripeConnectId"
            onChange={(e) => setStripeConnectId(e.target.value)}
          />
        </DialogContent>

        <DialogActions className="mb-4">
          <Button onClick={handleClose} className="cancel">
            Cancel
          </Button>

          <Button onClick={() => handleSubmit()} className="submit">
            {loader ? <CircularProgress className="submit_loader" /> : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
