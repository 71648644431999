import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./Heading.css";
import AddCategoryModal from "../Super-Admin/Category/AddCategoryModal";
import EditProfileBtn from "../Profile/EditProfileBtn";
import AddMenuModal from "../Restaurant-Admin/Menu/AddMenuModal";
import AddPromoCodeBtn from "../Restaurant-Admin/PromoCode/AddPromoCodeBtn";
import {
  AddTaxButton,
  AddVehicleButton,
  AddPriceButton,
} from "../AddButtons/AddButtons";
function Heading({
  addCategoryBtn,
  addMenuBtn,
  editProfileBtn,
  addPromoCodeBtn,
  addTax,
  addVehicle,
  addCourier,
}) {
  let location = useLocation();

  const [heading, setHeading] = React.useState("");

  React.useEffect(() => {
    setHeading(
      location.pathname.substring(1) === ""
        ? "Users"
        : location.pathname.substring(1) === "Store-Request"
        ? "Stores"
        : location.pathname.substring(1) === "orders-detail"
        ? "Orders Details"
        : location.pathname.substring(1) === "edit-profile"
        ? "Edit Profile"
        : location.pathname.substring(1) === "all-orders"
        ? "All Orders"
        : location.pathname.substring(1) === "promo-code"
        ? "Promo code"
        : location.pathname.substring(1) === "promotions"
        ? "Promotions"
        : location.pathname.substring(1) === "add-menu"
        ? "Add Menu"
        : location.pathname.substring(1) === "edit-menu"
        ? "Edit Menu"
        : location.pathname.substring(1) === "add-vehicle"
        ? "Add Vehicle"
        : location.pathname.substring(1) === "add-courier"
        ? "Add Courier"
        : location.pathname.substring(1) === "tax"
        ? "Tax"
        : location.pathname.substring(1) === "add-tax"
        ? "Add Tax"
        : location.pathname.substring(1) === "edit-time-slot"
        ? "Edit Time Slot"
        : location.pathname.substring(1)
    );
  }, [location]);

  return (
    <Row>
      <Col>
        {addCategoryBtn && <AddCategoryModal />}
        {editProfileBtn && <EditProfileBtn />}
        {addMenuBtn && <AddMenuModal />}
        {addPromoCodeBtn && <AddPromoCodeBtn />}
        {addTax && <AddTaxButton />}
        {addVehicle && <AddVehicleButton />}
        {addCourier && <AddPriceButton />}

        <div className="heading">{heading && heading}</div>
      </Col>
    </Row>
  );
}

export default Heading;
