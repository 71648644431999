import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { Container } from "@material-ui/core";
import "./Category.css";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../../../helper/Api";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { category } from "./../../../services/actions/categoryAction";
import CircularProgress from "@mui/material/CircularProgress";

export default function AddCategoryModal() {
  const { loader } = useSelector((state) => state.loader);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );
  const alert = useAlert();
  const history = useHistory();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [upload, setUpload] = useState(null);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [name, setName] = useState("");

  const [images, setImages] = useState([]);

  const createCategoryImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setUpload(e.target.files);

    setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          setImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const handleCategory = async (e) => {
    // let token = localStorage.getItem("superAdminToken");
    e.preventDefault();

    const file = upload;
    const fileData = file === null ? null : file[0];
    if (!fileData || !name) {
      alert.error("All fields are required");
      return false;
    }
    dispatch(loaderAction(true));

    var myForm = new FormData();
    myForm.append("name", fileData);

    myForm.append("file", fileData);

    let res = await addCategory(adminToken, myForm, name);
    setImagesPreview([]);
    setImages([]);
    if (res) {
      dispatch(category(adminToken));

      res.message && alert.success("Category has been added successfully");
      history.push("/Category");
      res.error && alert.error(res.error);
    }
    handleClose();
    dispatch(loaderAction(false));
  };

  return (
    <div className="add_category">
      <Button className="add_category_btn" onClick={() => handleClickOpen()}>
        <AddCircleOutlineIcon className="mr-2" />
        Add Category
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Add Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            type="text"
            fullWidth
            // variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <p className="ml-4">Upload Image</p>

        <Container>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            onChange={createCategoryImagesChange}
          />
          <div className="file">
            <label htmlFor="contained-button-file" className="file_upload">
              {imagesPreview.length === 0 ? (
                <CollectionsOutlinedIcon className="upload_image" />
              ) : (
                imagesPreview.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    className="max-w-100 img_preview"
                    alt="Category Preview"
                  />
                ))
              )}
            </label>
          </div>
        </Container>

        <DialogActions className="mb-4">
          <Button onClick={handleClose} className="cancel">
            Cancel
          </Button>

          {loader ? (
            <Button className="submit">
              Please wait... <CircularProgress className="submit_loader" />
            </Button>
          ) : (
            <Button onClick={handleCategory} className="submit">
              Add
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
