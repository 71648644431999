import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import { Container } from "@material-ui/core";
import "../Category/Category.css";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../../../helper/Api";
import { loaderAction } from "../../../services/actions/loaderAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { category } from "./../../../services/actions/categoryAction";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
export default function PayModal({ open, setOpen, rowData, setRowData }) {
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const { superAdminData, adminToken } = useSelector(
    (state) => state.isSuperAdmin
  );

  const alert = useAlert();

  const [state, setState] = useState({
    orderId: "",
    price: 0,
    paymentCurrency: "usd",
    deliveryTeamId: "",
    storeId: "",
  });

  const handleClose = () => {
    setOpen(false);
    setRowData(null);
    setLoader(false);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    let data = { ...state };

    if (value === "storeId") {
      data["storeId"] = rowData?.rowData?.storeId?._id;
      data["deliveryTeamId"] = "";
    } else if (value === "deliveryTeamId") {
      data["storeId"] = "";
      data["deliveryTeamId"] = rowData?.rowData?.deliveryTeamId?._id;
    } else {
      data[name] = value;
      data["orderId"] = rowData?.rowData?._id;
    }

    setState(data);
  };

  const handleSubmit = async (e) => {
    const config = {
      headers: { Authorization: adminToken },
    };

    try {
      let body = { ...state };

      if (!body.storeId && !body.deliveryTeamId) {
        alert.error("All fields are required");
        return false;
      }

      if (body.deliveryTeamId) {
        delete body.storeId;
      } else if (body.storeId) {
        delete body.deliveryTeamId;
      }
      setLoader(true);
      const { data } = await axios.post(
        body?.storeId ? Api.payToStore : Api.payToDriver,
        body,
        config
      );
      if (data.response.status.statusCode !== 200) {
        alert.error(data.response.status.customMessage);
      } else {
        alert.success(data.response.status.customMessage);
      }

      handleClose();
    } catch (error) {
      setLoader(false);
      alert.error(error.message);
    }
  };

  return (
    <div className="add_category">
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pay</DialogTitle>
        <DialogContent>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth className="mt-2">
              <InputLabel id="demo-simple-select-label">Paid To</InputLabel>
              <Select
                autoFocus
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Paid To"
                onChange={(e) => handleInput(e)}
              >
                {rowData?.rowData?.storeId && (
                  <MenuItem value={"storeId"}>Store</MenuItem>
                )}
                {rowData?.rowData?.deliveryTeamId && (
                  <MenuItem value={"deliveryTeamId"}>Driver</MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <TextField
            margin="dense"
            label="Currency"
            type="text"
            fullWidth
            disabled="true"
            value={state?.paymentCurrency}
            // variant="standard"
            name="paymentCurrency"
            onChange={handleInput}
          />
          <TextField
            // autoFocus
            margin="dense"
            label="Pay Amount"
            type="number"
            fullWidth
            name="price"
            onChange={handleInput}
          />
        </DialogContent>

        <DialogActions className="mb-4">
          <Button onClick={handleClose} className="cancel">
            Cancel
          </Button>

          <Button onClick={() => handleSubmit()} className="submit">
            {loader ? <CircularProgress className="submit_loader" /> : "Pay"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
