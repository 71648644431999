const baseUrl = process.env.REACT_APP_API_URI;
// const baseUrl = "https://api-kador2door.appsmaventech.com/api/v1/";

const superAdmin = `${baseUrl}superAdmin`;
const storeAdmin = `${baseUrl}storeAdmin`;

export const Api = {
  // Super Admin Api
  adminLogin: `${superAdmin}/superAdminLogin`,
  getAdminProfile: `${superAdmin}/superAdminLogin`,
  getUsersList: `${superAdmin}/getUsersList`,
  deleteUser: `${superAdmin}/deleteUser`,
  getRestaurantList: `${superAdmin}/getRestaurantList`,
  setRestaurantRequestStatus: `${superAdmin}/setRestaurantRequestStatus`,
  deleteRestaurant: `${superAdmin}/deleteRestaurant`,
  getGroceriesList: `${superAdmin}/getGroceriesList`,
  setGroceryRequestStatus: `${superAdmin}/setGroceryRequestStatus`,
  deleteGrocery: `${superAdmin}/deleteGrocery`,
  addCategory: `${superAdmin}/addCategory`,
  getCategoriesList: `${superAdmin}/getCategoriesList`,
  deleteCategory: `${superAdmin}/deleteCategory`,
  getDriversList: `${superAdmin}/getDriversList`,
  deleteDriver: `${superAdmin}/deleteDriver`,
  changePassword: `${superAdmin}/changePassword`,
  getOrdersList: `${superAdmin}/getOrdersList`,
  updateOrder: `${superAdmin}/updateOrder`,
  fileUpload: `https://api-kador2door.appsmaventech.com/fileupload`,
  userLogout: `${superAdmin}/userLogout`,
  getVehiclesList: `${superAdmin}/getVehiclesList`,
  addVehicle: `${superAdmin}/addVehicle`,
  updateVehicle: `${superAdmin}/updateVehicle`,
  deleteVehicle: `${superAdmin}/deleteVehicle`,

  getTaxesList: `${superAdmin}/getTaxesList`,
  addTax: `${superAdmin}/addTax`,
  updateTax: `${superAdmin}/updateTax`,
  deleteTax: `${superAdmin}/deleteTax`,

  getCourierPrices: `${superAdmin}/getCourierPrices`,
  addCourierPrice: `${superAdmin}/addCourierPrice`,
  updateCourierPrice: `${superAdmin}/updateCourierPrice`,
  deleteCourierPrice: `${superAdmin}/deleteCourierPrice`,

  // new api

  // Restaurants Admin Api
  storeRegister: `${storeAdmin}/storeRegister`,
  storeLogin: `${storeAdmin}/storeLogin`,
  storeGetProfile: `${storeAdmin}/storeGetProfile`,
  storeLogout: `${storeAdmin}/storeLogout`,

  storeUpdateProfile: `${storeAdmin}/storeUpdateProfile`,
  addRestaurant: `${storeAdmin}/addRestaurant`,
  showRestaurant: `${storeAdmin}/showRestaurant`,

  addGrocery: `${storeAdmin}/addGrocery`,
  showGrocery: `${storeAdmin}/showGrocery`,
  getAllOrders: `${storeAdmin}/getAllOrders`,
  updateOrderStatus: `${storeAdmin}/updateOrderStatus`,

  getCategories: `${storeAdmin}/getCategories`,
  addProduct: `${storeAdmin}/addProduct`,
  editProduct: `${storeAdmin}/editProduct`,
  showProducts: `${storeAdmin}/showProducts`,
  addPromoCode: `${storeAdmin}/addPromoCode`,

  showPromoCode: `${storeAdmin}/showPromoCode`,
  editPromoCode: `${storeAdmin}/editPromoCode`,
  deletePromoCode: `${storeAdmin}/deletePromoCode`,
  updateRestaurantProfile: `${storeAdmin}/updateRestaurantProfile`,

  updateGroceryProfile: `${storeAdmin}/updateGroceryProfile`,
  showServicesList: `${storeAdmin}/showServicesList`,

  // common apis for all
  showStoreAvailableServiceProfile: `${storeAdmin}/showStoreAvailableServiceProfile`,
  editTimeSlots: `${storeAdmin}/editTimeSlots`,
  showTransactionInfo: `${storeAdmin}/showTransactionInfo`,

  // pay orders/ drivers
  getStoreList: `${superAdmin}/getStoreList`,
  updateStoreProfile: `${superAdmin}/updateStoreProfile`,
  updateDriverProfile: `${superAdmin}/updateDriverProfile`,
  ordersList: `${superAdmin}/ordersList`,
  payToStore: `${superAdmin}/payToStore`,
  payToDriver: `${superAdmin}/payToDriver`,
};
