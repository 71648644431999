import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Profile.css";

export const EditProfileBtn = () => {
  return (
    <div className="add_category">
      <Link to="/edit-profile" className="">
        <Button className="edit_profile_btn">Edit Profile</Button>
      </Link>
    </div>
  );
};

export default EditProfileBtn;
