import {
  ADMIN_USER,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
} from "../constants/AdminConstants";
import axios from "axios";
import { Api } from "../../helper/ApiList";
import { loaderAction } from "./loaderAction";
import { STORE_LOGIN_RESET } from "../constants/StoreConstants";
import {
  removeAuthentication,
  removeSession,
  setEmail,
  setIsAdmin,
  setSession,
} from "../../helper/cookies";

// Login
export const superAdminLogin = (adminType, loginData) => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({ type: LOGIN_REQUEST });
  dispatch({ type: STORE_LOGIN_RESET });

  const { data } = await axios.post(Api.adminLogin, loginData);

  if (data.response) {
    const { status, superAdminData } = data.response;

    if (status.statusCode === 200) {
      dispatch({ type: ADMIN_USER, payload: adminType });
      let adminData = superAdminData[0];
      // localStorage.setItem("superAdminToken", adminData.accessToken);

      dispatch(loaderAction(true));

      // cookie start new session
      let token = adminData.accessToken;
      let email = adminData.email;
      let isAdmin = adminType;
      setSession(token, () => {
        setIsAdmin(isAdmin, () => {
          setEmail(email, () => {
            setTimeout(() => {
              window.location.href = "/";
            }, 100);
          });
        });
      });
      dispatch(loaderAction(false));

      // cookie start end session

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          data: adminData,
          isAdmin: adminType,
          accessToken: adminData.accessToken,
        },
      });
      dispatch(loaderAction(false));
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: { message: status.customMessage, data },
      });
      dispatch(loaderAction(false));
    }
  } else {
    dispatch(loaderAction(false));
    dispatch({ type: LOGIN_FAIL, payload: data });
  }
};

export const isUserLoggedIn = (adminType) => {
  return async (dispatch) => {
    dispatch(loaderAction(true));
    const token = localStorage.getItem("superAdminToken");

    if (token) {
      dispatch({ type: ADMIN_USER, payload: adminType });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: token,
      });
      dispatch(loaderAction(false));
    } else {
      dispatch({
        type: LOGIN_FAIL,
        payload: { message: "Failed to login" },
      });
      dispatch(loaderAction(false));
    }
  };
};

// export const logoutSuperAdmin = () => {
//   return async (dispatch) => {
//     localStorage.clear();
//     console.clear();

//     dispatch({
//       type: LOGOUT_REQUEST,
//     });
//   };
// };

export const logoutSuperAdmin = () => async (dispatch) => {
  dispatch(loaderAction(true));
  removeAuthentication(() => {
    removeSession(() => {
      localStorage.clear();
      console.clear();
      setTimeout(() => {
        window.location.href = "/login";
      }, 100);
      dispatch(loaderAction(false));
    });
  });
};
