import React from "react";

const ForgotPassword = () => {
  return (
    <div>
      <div className="container forget-password">
        <div className="row">
          <div className="col-md-12 col-md-offset-4">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="text-center">
                  <img
                    src="https://i.ibb.co/rshckyB/car-key.png"
                    alt="car-key"
                    border="0"
                  />
                  <h2 className="text-center">Reset Password?</h2>
                  <p>You can reset your password here.</p>
                  <div
                    id="register-form"
                    role="form"
                    autocomplete="off"
                    className="form"
                  >
                    <div className="form-group text-left">
                      <label>New Password</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="glyphicon glyphicon-envelope color-blue"></i>
                        </span>
                        <input
                          id=""
                          name="forgetAnswer"
                          placeholder=""
                          className="form-control custom-filed"
                          type="password"
                        />
                      </div>
                    </div>
                    <div className="form-group text-left">
                      <label>Confirm Password</label>
                      <div className="input-group">
                        <span className="input-group-addon">
                          <i className="glyphicon glyphicon-envelope color-blue"></i>
                        </span>
                        <input
                          id=""
                          name="forgetAnswer"
                          placeholder=""
                          className="form-control custom-filed"
                          type="password"
                        />
                      </div>
                    </div>
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-primary custom-btn"
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- modal --> */}
      {/* <!-- Button trigger modal --> */}
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <h4>Successful</h4>
              <i className="fa fa-check-circle custom-icon"></i>
              <p className="pt-4 pb-4">
                Your password has been reset please login to application
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
