import axios from "axios";
import { Api } from "../../helper/ApiList";
import { MENU_SUCCESS, STORE_REQUEST } from "../constants/Constants";
import { loaderAction } from "./loaderAction";
import { STORE_SUCCESS, STORE_FAIL } from "./../constants/Constants";

export const store = (token) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: STORE_REQUEST });

  const { data } = await axios({
    method: "post",
    headers: { Authorization: `${token}` },
    url: `${Api.getRestaurantList}?type=all`,
  });

  if (data.response) {
    const { status, resultData } = data.response;

    if (status.statusCode === 200) {
      let arr = [];
      if (resultData.length === 0) {
        dispatch({ type: STORE_SUCCESS, payload: resultData });
        dispatch(loaderAction(false));
      } else {
        resultData &&
          resultData.forEach((store, index) => {
            store.index = index + 1;

            arr.push(store);
          });
        dispatch({ type: STORE_SUCCESS, payload: arr && arr });
        dispatch(loaderAction(false));
      }
    }
  } else {
    dispatch({ type: STORE_FAIL });

    dispatch(loaderAction(false));
  }
};

export const getMenuInfo = (data) => async (dispatch) => {
  dispatch(loaderAction(true));

  dispatch({ type: MENU_SUCCESS, payload: data });
  dispatch(loaderAction(false));
};
