import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Col, Form, Row, Button, Container } from "react-bootstrap";

import Sidebar from "../../Super-Admin/Sidebar/Sidebar";
import Heading from "../../Heading/Heading";
import { useStyles } from "../../../utils/useStyles";
import { useDispatch, useSelector } from "react-redux";
import useFetch from "../../../hooks/useFetch";
import { Api } from "../../../helper/ApiList";
import axios from "axios";
import { useAlert } from "react-alert";
import { loaderAction } from "../../../services/actions/loaderAction";

export default function Promotions() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.isStoreAdmin);
  const alert = useAlert();

  const { data } = useFetch(Api.showServicesList, token, "get");

  const { type } = useFetch(Api.showStoreAvailableServiceProfile, token, "get");

  const [discountAction, setDiscountAction] = useState(false);
  const [discount, setDiscount] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");

  const [inputData, setInputData] = React.useState({
    serviceId: "",
    name: "",
    description: "",
    code: "",
    discountPercentage: "",
    discountAmount: "",
    isForMultipleUse: "",
    validUpTo: "",
  });

  const handleForm = (e) => {
    const { name, value } = e.target;
    let data = { ...inputData };

    if (name === "discountAmount") {
      data[name] = value;
      data["discountPercentage"] = "";
      setDiscount(name === "discountAmount" ? value : "");
      setDiscountPercentage(name === "discountAmount" ? "" : "");

      setInputData(data);
      return;
    } else if (name === "discountPercentage") {
      data["discountAmount"] = "";
      data[name] = value;
      setDiscount(name === "discountPercentage" ? "" : "");
      setDiscountPercentage(name === "discountPercentage" ? value : "");
      setInputData(data);
      return;
    } else {
      data[name] = value;
      setInputData(data);
      return;
    }
  };

  const handleSubmit = async () => {
    dispatch(loaderAction(true));
    let config = {
      headers: { Authorization: `${token}` },
    };

    try {
      const { data } = await axios.post(Api.addPromoCode, inputData, config);

      const { response } = data;
      if (response.status.statusCode === 200) {
        alert.success("Promo code has been successfully added");
        history.push("/promo-code");
      } else {
        alert.info(response.status.customMessage);
      }
      dispatch(loaderAction(false));
    } catch (error) {
      const { response } = error;

      alert.error(response.data.message);
      dispatch(loaderAction(false));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Sidebar />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Heading />

          <Container>
            <Row className="pt-5 edit_profile_form">
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Select Service Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="serviceId"
                    onChange={(e) => handleForm(e)}
                  >
                    <option></option>
                    {data && data.length > 0
                      ? data.map(
                          (service, index) =>
                            type == service.name && (
                              <option value={service._id} key={index}>
                                {service.name}
                              </option>
                            )
                        )
                      : "not found"}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Promo Code</Form.Label>
                  <Form.Control
                    placeholder="HYY6547"
                    name="code"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Discount Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="5.00"
                    onClick={() => setDiscountAction(true)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={discountAction ? discount : ""}
                    onChange={(e) => handleForm(e)}
                    name="discountAmount"
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Discount Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="$5.00"
                    name="discountPercentage"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onClick={() => setDiscountAction(false)}
                    value={discountAction ? "" : discountPercentage}
                    onChange={(e) => {
                      e.target.value.length === 3
                        ? alert.info("Only two digit number is valid")
                        : handleForm(e);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Valid Upto</Form.Label>
                  <Form.Control
                    type="date"
                    name="validUpTo"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    placeholder="name"
                    name="name"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Multiple use</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="isForMultipleUse"
                    onChange={(e) => handleForm(e)}
                  >
                    <option></option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Description"
                    name="description"
                    onChange={(e) => handleForm(e)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              className="edit_profile_btn p-4 pt-2 pb-2 "
              onClick={() => handleSubmit()}
            >
              {" "}
              Submit
            </Button>
          </Container>
        </main>
      </div>
    </>
  );
}
