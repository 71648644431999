import axios from "axios";
import { Api } from "../../../helper/ApiList";
import {
  removeAuthentication,
  removeSession,
  setEmail,
  setIsAdmin,
  setSession,
} from "../../../helper/cookies";
import { SUPER_ADMIN_RESET } from "../../constants/AdminConstants";
import {
  STORE_LOGIN_SUCCESS,
  STORE_LOGIN_REQUEST,
  STORE_LOGIN_FAIL,
  STORE_LOGOUT_REQUEST,
} from "../../constants/StoreConstants";
import { loaderAction } from "../loaderAction";

export const storeAdminLogin = (adminType, loginData) => async (dispatch) => {
  dispatch(loaderAction(true));
  dispatch({ type: STORE_LOGIN_REQUEST });
  dispatch({ type: SUPER_ADMIN_RESET });

  const { data } = await axios.post(Api.storeLogin, loginData);

  if (data.response) {
    const { status, storeData } = data.response;

    if (status.statusCode === 200) {
      // cookie start new session
      let token = storeData.accessToken;
      let email = storeData.email;
      let isAdmin = adminType;
      setSession(token, () => {
        setIsAdmin(isAdmin, () => {
          setEmail(email, () => {
            setTimeout(() => {
              window.location.href = "/all-orders";
            }, 100);
          });
        });
      });
      dispatch(loaderAction(false));

      // cookie start end session

      dispatch({
        type: STORE_LOGIN_SUCCESS,
        payload: {
          data: storeData,
          isAdmin: adminType,
          accessToken: storeData.accessToken,
        },
      });
      dispatch(loaderAction(false));
    } else {
      dispatch({
        type: STORE_LOGIN_FAIL,
        payload: { message: status.customMessage, data },
      });
      dispatch(loaderAction(false));
    }
  } else {
    dispatch(loaderAction(false));
    dispatch({ type: STORE_LOGIN_FAIL, payload: data });
  }
};

export const logoutStoreAdmin = () => async (dispatch) => {
  dispatch(loaderAction(true));
  removeAuthentication(() => {
    removeSession(() => {
      localStorage.clear();
      console.clear();
      setTimeout(() => {
        window.location.href = "/restaurant/login";
      }, 100);
      dispatch(loaderAction(false));
    });
  });
};
