import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import LoginIcon from "./logo-login.png";
import "./Login.css";
import { superAdminLogin } from "../../services/actions";
function SuperAdminLogin() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const isAdmin = true;

  const { isAuthenticated, message } = useSelector(
    (state) => state.isSuperAdmin
  );
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  console.log("test", isAuthenticated);
  useEffect(() => {
    message && alert.error(message);
  }, [message, alert]);
  // useEffect(() => {
  //   localStorage.setItem("superAdminToken", "true");
  // }, []);

  const loginHandle = () => {
    if (!email || !password) {
      alert.info("Please enter a valid email or password");
    } else {
      let data = {
        email,
        password,
        deviceType: "",
        deviceToken: "",
      };
      dispatch(superAdminLogin(isAdmin, data));
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="login_left_side">
            <div className="logo">
              <img
                src={LoginIcon}
                alt=""
                srcSet=""
                className="logo_login_icon"
              />
            </div>
          </Col>
          <Col className="login_right_side">
            <div className="w-75" autoComplete="off">
              <div className="logo">
                <img
                  src={LoginIcon}
                  alt=""
                  srcSet=""
                  className="logo_login_icon"
                />
              </div>
              <p className="login_heading">Login</p>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <Button
                className="login_button"
                type="submit"
                onClick={() => loginHandle()}
              >
                Login
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SuperAdminLogin;
