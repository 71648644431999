import React from "react";

import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useSelector((state) => state.isSuperAdmin);
  const { isStoreAuthenticated } = useSelector((state) => state.isStoreAdmin);

  console.log(isAuthenticated, "authenticate", isStoreAuthenticated);

  return (
    <>
      {isStoreAuthenticated ? (
        <Route
          {...rest}
          render={(props) =>
            isStoreAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to={"/restaurant/login"} />
            )
          }
        />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to={"/login"} />
            )
          }
        />
      )}
    </>
  );
}

function StorePrivateRoute({ component: Component, ...rest }) {
  const { isStoreAuthenticated } = useSelector((state) => state.isStoreAdmin);

  return (
    <Route
      {...rest}
      render={(props) =>
        isStoreAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/restaurant/login"} />
        )
      }
    />
  );
}

export { PrivateRoute, StorePrivateRoute };
