import {
  STORE_LOGIN_REQUEST,
  STORE_LOGIN_SUCCESS,
  STORE_LOGIN_FAIL,
  STORE_LOGOUT_REQUEST,
  STORE_LOGIN_RESET,
} from "../../constants/StoreConstants";

const initialState = {
  isStoreAuthenticated: false,
  loader: false,
  superAdminData: null,
  isStoreUser: false,
  token: null,
  message: null,
};

export const storeAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_LOGIN_RESET:
      return {
        ...initialState,
      };
    case STORE_LOGOUT_REQUEST:
      return {
        ...initialState,
      };
    case STORE_LOGIN_REQUEST:
      return {
        loader: true,
        isStoreAuthenticated: false,
      };

    case STORE_LOGIN_SUCCESS:
      return {
        ...state,
        loader: false,
        superAdminData: action.payload.data,
        isStoreAuthenticated: true,
        isStoreUser: action.payload.isAdmin,
        token: action.payload.accessToken,
      };
    case STORE_LOGIN_FAIL:
      return {
        ...state,
        isStoreAuthenticated: false,
        message: action.payload.message,
        superAdminData: action.payload.data,

        loader: false,
      };

    default:
      return state;
  }
};
